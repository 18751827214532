import React, { useState } from "react";
import { EuiButtonIcon } from "@elastic/eui";
import { useMutation, gql } from "@apollo/client";

const PROMOTE = gql`
  mutation ($id: ID!, $promote: Boolean!) {
    promoteFood(id: $id, promote: $promote) {
      success
    }
  }
`;

export default function PromoteFood({ id, value }) {
  const [promote, setPromote] = useState(value);
  const [promoteMutation, { loading }] = useMutation(PROMOTE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  return (
    <EuiButtonIcon
      iconType={promote ? "starFilled" : "starEmpty"}
      aria-label="promote"
      isDisabled={loading}
      onClick={() => {
        promoteMutation({ variables: {id: id, promote: !promote}})
        setPromote(!promote);
      }}
    />
  );
}
