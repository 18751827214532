import { gql } from "@apollo/client";

const UPDATE_RECIPE_INGREDIENTS = gql`
  mutation updateRecipeIngredients($id: ID!, $ingredients: [String]!) {
    updateRecipeIngredients(id: $id, ingredients: $ingredients) {
      id
    }
  }
`;

export default UPDATE_RECIPE_INGREDIENTS;
