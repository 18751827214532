import { gql } from "@apollo/client";

const GET_RECIPES = gql`
  query recipes(
    $query: String
    $first: Int
    $cursor: String
    $isActive: Boolean
    $isPremium: Boolean
    $isPurchasable: Boolean
    $sourceUrlWorks: Boolean
    $hasImage: Boolean
    $useMatchPhrase: Boolean
    $caloriesRange: [Float]
    $proteinRange: [Float]
    $fatRange: [Float]
    $carbsRange: [Float]
    $omega3Range: [Float]
    $ingredientRange: [Int]
    $totalTimeRange: [Int]
    $meals: [String]
    $recommendedCourses: Boolean
    $hasKnownIngredients: Boolean
    $mustIngredients: [String]
    $tags: [String]
    $courses: [String]
    $author: String
    $source: String
    $restrictions: [String]
  ) {
    recipes(
      query: $query
      first: $first
      after: $cursor
      isActive: $isActive
      isPremium: $isPremium
      isPurchasable: $isPurchasable
      sourceUrlWorks: $sourceUrlWorks
      hasImage: $hasImage
      useMatchPhrase: $useMatchPhrase
      caloriesRange: $caloriesRange
      proteinRange: $proteinRange
      fatRange: $fatRange
      carbsRange: $carbsRange
      omega3Range: $omega3Range
      ingredientRange: $ingredientRange
      totalTimeRange: $totalTimeRange
      meals: $meals
      recommendedCourses: $recommendedCourses
      hasKnownIngredients: $hasKnownIngredients
      mustIngredients: $mustIngredients
      tags: $tags
      courses: $courses
      author: $author
      source: $source
      restrictions: $restrictions
    ) {
      edges {
        node {
          id
          indexedAt
          databaseId
          name
          author
          mainImage
          squareImage
          isActive
          isPremium
          servingWeight
          courses
          mealTags
          numberOfServings
          tags
          ingredientLines
          ingredients
          weightInGrams
          hasTrainableIngredients
          nutrientsPerServing {
            calories
          }
          nutritionalInfo {
            calories
            protein
            carbs
            fat
            sugar
            fiber
            saturatedFat
            monounsaturatedFat
            polyunsaturatedFat
            transFat
            cholesterol
            sodium
            potassium
            vitaminA
            vitaminC
            calcium
            iron
            netcarbs
          }
          rawNutrients {
            calories
            protein
            carbs
            fat
            sugar
            fiber
            saturatedFat
            monounsaturatedFat
            polyunsaturatedFat
            transFat
            cholesterol
            sodium
            potassium
            vitaminA
            vitaminC
            calcium
            iron
          }
          caloriesPerServing {
            protein
            carbs
            fat
            dha
            dpa
            epa
            ala
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default GET_RECIPES;
