import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const INGREDIENT_GROUP = gql`
  {
    ingredientGroups {
      count
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function IngredientGroupsComboBox({ currentGroups, setGroups }) {
  const { loading, error, data } = useQuery(INGREDIENT_GROUP);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.ingredientGroups.edges.forEach((e) =>
      options.push({ label: e.node.name, value: e.node.id, key: e.node.id })
    );
  }

  return (
    <EuiComboBox
      placeholder="Groups"
      options={options}
      selectedOptions={currentGroups}
      onChange={(tags) => setGroups(tags)}
      isClearable={true}
      data-test-subj="recipeMealTags"
      isLoading={loading}
      fullWidth={true}
    />
  );
}
