import { gql } from "@apollo/client";

const UPDATE_RECIPE_NUTRIENTS_PER_SERVING = gql`
  mutation updateRecipeNutrientsPerServing(
    $id: ID!
    $nutrients: [NutrientInput]
    $weightInGrams: Float
  ) {
    updateRecipeNutrientsPerServing(
      id: $id
      nutrients: $nutrients
      weightInGrams: $weightInGrams
    ) {
      success
    }
  }
`;

export default UPDATE_RECIPE_NUTRIENTS_PER_SERVING;
