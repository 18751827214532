import React, { useState } from "react";

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiButton,
  EuiFieldText,
  EuiFieldPassword,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from "@elastic/eui";

import conf from "../utils";
import { isLoggedInVar } from "../cache";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    fetch(`${conf.API_URL}/api-token-auth/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseJson) => {
            localStorage.setItem("token", responseJson.token);
            isLoggedInVar(true);
          });
        } else if (response.status === 400) {
          response.json().then((responseJson) => {
            console.log(responseJson);
            setErrors(responseJson.non_field_errors);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const errors = [
  //   "Here's an example of an error",
  //   'You might have more than one error, so pass an array.',
  // ]

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageContent verticalPosition="center" horizontalPosition="center">
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Suggestic</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiForm isInvalid={errors} error={errors}>
              <form onSubmit={handleSubmit}>
                <EuiFormRow label="Username">
                  <EuiFieldText
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </EuiFormRow>
                <EuiFormRow label="Password">
                  <EuiFieldPassword
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </EuiFormRow>

                <EuiSpacer />

                <EuiButton type="submit" fill>
                  Login
                </EuiButton>
              </form>
            </EuiForm>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}
