import React, { useState } from "react";

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFormRow,
  EuiFlexItem,
  EuiStat,
  EuiI18nNumber,
} from "@elastic/eui";
import { useLazyQuery } from "@apollo/client";
import INGREDIENT_LINES from "../querys/ingredientLines";

import IngredientLinesResults from "../components/IngredientLinesResults";
import IngredientLinesAggComboBox from "../components/IngredientLinesAggComboBox";

export default function IngredientLines() {
  const [query, setQuery] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [search, { loading, error, data }] = useLazyQuery(INGREDIENT_LINES);

  function onSearch() {
    const mustIngredients = ingredients.map((i) => i.value);
    search({ variables: { query: query, ingredients: mustIngredients } });
  }

  if (error) return `Error! ${error}`;

  return (
    <>
      <EuiFlexItem>
        <EuiFieldSearch
          placeholder="Search..."
          fullWidth
          value={query}
          incremental={false}
          onSearch={onSearch}
          onChange={(e) => setQuery(e.target.value)}
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow>
            <IngredientLinesAggComboBox
              ingredientsChanged={(ings) => setIngredients(ings)}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      {data && !loading ? (
        <>
          <EuiStat
            title={
              data ? <EuiI18nNumber value={data.ingredientLines.count} /> : 0
            }
            description="Total Ingredient lines"
            titleSize="l"
            isLoading={loading}
          />
          <IngredientLinesResults data={data} />
        </>
      ) : null}
    </>
  );
}
