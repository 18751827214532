import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiButton,
  EuiTitle,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFormRow,
  EuiFieldText,
  EuiSelect,
  EuiSwitch,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSpacer,
} from "@elastic/eui";
import { useReactFlow } from "reactflow";

const QUESTION_DELETE = gql`
  mutation questionDelete($input: QuestionDeleteInput!) {
    questionDelete(input: $input) {
      success
    }
  }
`;

const QUESTION_UPDATE = gql`
  mutation questionUpdate($input: QuestionUpdateInput!) {
    questionUpdate(input: $input) {
      success
      question {
        id
        text
        questionType
        canSkip
        isInitialQuestion
      }
    }
  }
`;

export default function QuestionForm({
  node,
  toggleIsFlyoutVisible,
  setNodes,
}) {
  const questionTypeOptions = [
    { value: "SINGLE_SELECT", text: "Single-select" },
    { value: "MULTI_SELECT", text: "Multi-select" },
    { value: "OPEN_TEXT", text: "Open-text" },
    { value: "OPEN_NUMBER", text: "Open-number" },
    { value: "DATE_PICKER", text: "Date-picker" },
  ];
  const [questionDelete] = useMutation(QUESTION_DELETE);
  const [questionUpdate] = useMutation(QUESTION_UPDATE);
  const reactFlowInstance = useReactFlow();

  const [text, setText] = useState(node.text, "");
  const [questionType, setQuestionType] = useState(node.questionType);
  const [canSkip, setCanSkip] = useState(node.canSkip);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const close = () => {
    toggleIsFlyoutVisible(false);
  };

  const remove = async () => {
    const input = { id: node.id };
    try {
      const response = await questionDelete({ variables: { input } });
      if (response.data?.questionDelete?.success) {
        reactFlowInstance.deleteElements({ nodes: [{ id: node.id }] });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const update = async () => {
    const input = { id: node.id };
    if (node.text !== text) input.text = text;
    if (node.questionType !== questionType) input.questionType = questionType;
    if (node.canSkip !== canSkip) input.canSkip = canSkip;

    try {
      const response = await questionUpdate({ variables: { input } });
      if (response.data?.questionUpdate?.success) {
        // TODO: Notify of success?
      }
    } catch (err) {
      console.error(err);
    }
  };

  let modal;

  if (isModalVisible) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Modal title</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          Answer
          <EuiSpacer />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
          <EuiButton onClick={closeModal} fill>
            Save
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  useEffect(() => {
    setNodes((nds) =>
      nds.map((nd) => {
        if (nd.id === node.id) {
          nd.data = {
            ...nd.data,
            label: text,
          };
        }

        return nd;
      })
    );
  }, [text, setNodes]);

  return (
    <>
      {modal}
      <EuiFlyout onClose={close} aria-labelledby="flyoutTitle" size="s">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">Question</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow label="Text">
            <EuiFieldText
              id={"text-input"}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              aria-label="Use aria labels when no actual label is in use"
            />
          </EuiFormRow>
          <EuiFormRow
            label="Question type"
            helpText="Defines the type of question."
          >
            <EuiSelect
              hasNoInitialSelection
              id="selectDocExample"
              options={questionTypeOptions}
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Question can be skiped?">
            <EuiSwitch
              name="Skip"
              label=""
              checked={canSkip}
              onChange={() => setCanSkip(!canSkip)}
            />
          </EuiFormRow>
          <EuiButton onClick={showModal}>Add Answer</EuiButton>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={close} flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="trash"
                onClick={remove}
                flush="left"
                color="danger"
              >
                Delete
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton onClick={update} fill>
                Update
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </>
  );
}
