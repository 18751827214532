import React, { useState, Fragment } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiBreadcrumbs,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiToast,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate, Link } from "@reach/router";

import ListFieldText from "../components/ListFieldText";
import UPDATE_TRANLATION_RECIPE from "../mutations/updateTranslationRecipe";

export default function ChangeRecipeTranslationForm({ recipe }) {
  const [displayToast, setDisplayToast] = useState(false);
  const [name, setName] = useState(recipe.name);
  const [ingredients, setIngredients] = useState(
    recipe.ingredients ? recipe.ingredients : []
  );
  const [ingLines, setIngLines] = useState(
    recipe.ingredientLines ? recipe.ingredientLines : []
  );
  const [instructions, setInstructions] = useState(
    recipe.instructions ? recipe.instructions : []
  );

  const [updateRecipe, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_TRANLATION_RECIPE, {
      errorPolicy: "all",
      onCompleted(data) {
        setDisplayToast(true);
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  const _updateRecipe = () => {
    let variables = { id: recipe.id };
    if (recipe.name !== name) variables.name = name;
    if (recipe.ingredientLines !== ingLines)
      variables.ingredientLines = ingLines;
    if (recipe.ingredients !== ingredients) variables.ingredients = ingredients;
    if (recipe.instructions !== instructions)
      variables.instructions = instructions;

    updateRecipe({ variables: { input: variables } });
  };

  const breadcrumbs = [
    {
      text: "Recipes",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes`);
      },
    },
    {
      text: `${recipe.databaseId}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes/${recipe.databaseId}`);
      },
    },
    {
      text: "Change",
    },
  ];

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Recipe was updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  return (
    <Fragment>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer />
      <EuiPageContentHeaderSection>
        <EuiTitle size="s">
          <h1>Change Translation Recipe</h1>
        </EuiTitle>
        <Link to={`/recipes/${recipe.databaseId}/history`}>History</Link>
      </EuiPageContentHeaderSection>
      <EuiSpacer />

      <EuiForm component="form">
        <EuiFormRow label="Name" helpText="The name.">
          <EuiFieldText
            name="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Ingredient Lines">
          <ListFieldText
            list={ingLines}
            setList={setIngLines}
            addText="Add another ingredient line"
            parse={false}
          />
        </EuiFormRow>

        <EuiFormRow label="Ingredients">
          <ListFieldText
            list={ingredients}
            setList={setIngredients}
            addText="Add another ingredient"
            parse={false}
          />
        </EuiFormRow>

        <EuiFormRow label="Instructions">
          <ListFieldText
            list={instructions}
            setList={setInstructions}
            addText="Add another instruction"
            textArea={true}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton fill onClick={_updateRecipe} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>

      <EuiSpacer />
      <Toast />
    </Fragment>
  );
}
