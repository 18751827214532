import React from "react";
import { useQuery } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";

import ChangeFoodForm from "../components/ChangeFoodForm";
import FOOD_DETAIL from "../querys/foodDetail";

function ChangeFood({ foodId }) {
  const id = btoa(`Food:${foodId}`);
  const { loading, error, data } = useQuery(FOOD_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return <ChangeFoodForm food={data.food} />;
}

export default ChangeFood;
