import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import RECIPE_TAG_CREATE from "../mutations/RecipeTagCreate";

const ALL_TAGS = gql`
  {
    allTags
  }
`;

function TagsComboBox({ currentTags, setTags }) {
  const { loading, error, data } = useQuery(ALL_TAGS);
  const [recipeTagCreate] = useMutation(RECIPE_TAG_CREATE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  if (error) return `Error! ${error}`;

  let options = [];
  if (data) {
    data.allTags.forEach((tag) => options.push({ label: tag }));
  }

  const _recipeTagCreate = (termName) => {
    recipeTagCreate({
      variables: { input: { termName: termName } },
    });
  };

  const onCreateOption = (searchValue = []) => {
    const normalizedSearchValue = searchValue.trim();

    if (!normalizedSearchValue) {
      return;
    }

    const customOption = { label: searchValue };
    options.push(customOption);
    currentTags.push(customOption);
    // Save new tag in backend
    _recipeTagCreate(searchValue);
  };

  return (
    <EuiComboBox
      placeholder="Tags"
      options={options}
      selectedOptions={currentTags}
      onChange={(tags) => setTags(tags)}
      isClearable={true}
      data-test-subj="recipeTags"
      isLoading={loading}
      fullWidth={true}
      onCreateOption={onCreateOption}
    />
  );
}
export default TagsComboBox;
