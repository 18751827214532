import { gql } from "@apollo/client";

const HISTORY = gql`
  query allHistory($objectType: ObjectType!, $id: ID!) {
    allHistory(objectType: $objectType, id: $id) {
      user
      action
      objectType
      objectId
      date
      change
    }
  }
`;

export default HISTORY;
