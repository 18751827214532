import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const ALL_CUISINES = gql`
  {
    allCuisines
  }
`;

export default function CuisinesComboBox({ currentCuisines, setCuisines }) {
  const { loading, error, data } = useQuery(ALL_CUISINES);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.allCuisines.forEach((cuisine) => options.push({ label: cuisine }));
  }

  return (
    <EuiComboBox
      placeholder="Cuisines"
      options={options}
      selectedOptions={currentCuisines}
      onChange={(cuisines) => setCuisines(cuisines)}
      isClearable={true}
      data-test-subj="recipeCuisines"
      isLoading={loading}
      fullWidth={true}
    />
  );
}
