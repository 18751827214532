import React from "react";

import { EuiSelect } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const ALL_AUTHORS = gql`
  {
    allAuthors
  }
`;

export default function AuthorSelect({ item, setItem }) {
  const { loading, error, data } = useQuery(ALL_AUTHORS);

  if (error) return `Error! ${error}`;

  let options = [{value: "", text: "--"}];

  if (data) {
    data.allAuthors.forEach((a) => options.push({ value: a, text: a }));
    let optFilter = options.filter((o) => o.value === item);
    if (item !== undefined && optFilter && !optFilter.length) {
      item = undefined;
    }
    return (
      <EuiSelect
        hasNoInitialSelection
        id="selectDocExample"
        options={options}
        value={item}
        onChange={(e) => setItem(e.target.value)}
        isLoading={loading}
      />
    );
  }
  return null;
}
