import React from "react";

function NutrientsPerServing({ recipe }) {
  if (recipe.nutrientsPerServing) {
    let item = recipe.nutrientsPerServing;
    return (
      <ul>
        <li>
          <b>calories:</b> {item.calories !== undefined ? item.calories : "-"}
        </li>
        <li>
          <b>sugar:</b> {item.sugar !== undefined ? item.sugar : "-"}
        </li>
        <li>
          <b>fiber:</b> {item.fiber !== undefined ? item.fiber : "-"}
        </li>
        <li>
          <b>saturated_fat:</b>{" "}
          {item.saturatedFat !== undefined ? item.saturatedFat : "-"}
        </li>
        <li>
          <b>monounsaturated_fat:</b>{" "}
          {item.monounsaturatedFat !== undefined
            ? item.monounsaturatedFat
            : "-"}
        </li>
        <li>
          <b>polyunsaturated_fat:</b>{" "}
          {item.polyunsaturatedFat !== undefined
            ? item.polyunsaturatedFat
            : "-"}
        </li>
        <li>
          <b>trans_fat:</b>{" "}
          {item.transFat !== undefined ? item.transFat : "-"}
        </li>
        <li>
          <b>cholesterol:</b>{" "}
          {item.cholesterol !== undefined ? item.cholesterol : "-"}
        </li>
        <li>
          <b>sodium:</b> {item.sodium !== undefined ? item.sodium : "-"}
        </li>
        <li>
          <b>potassium:</b>{" "}
          {item.potassium !== undefined ? item.potassium : "-"}
        </li>
        <li>
          <b>vitamin_a:</b> {item.vitaminA !== undefined ? item.vitaminA : "-"}
        </li>
        <li>
          <b>vitamin_c:</b> {item.vitaminC !== undefined ? item.vitaminC : "-"}
        </li>
        <li>
          <b>vitamin_b6:</b>{" "}
          {item.vitaminB6 !== undefined ? item.vitaminB6 : "-"}
        </li>
        <li>
          <b>vitamin_b12:</b>{" "}
          {item.vitaminB12 !== undefined ? item.vitaminB12 : "-"}
        </li>
        <li>
          <b>vitamin_b7:</b>{" "}
          {item.vitaminB7 !== undefined ? item.vitaminB7 : "-"}
        </li>
        <li>
          <b>vitamin_d:</b> {item.vitaminD !== undefined ? item.vitaminD : "-"}
        </li>
        <li>
          <b>vitamin_e:</b> {item.vitaminE !== undefined ? item.vitaminE : "-"}
        </li>
        <li>
          <b>choline:</b> {item.choline !== undefined ? item.choline : "-"}
        </li>
        <li>
          <b>calcium:</b> {item.calcium !== undefined ? item.calcium : "-"}
        </li>
        <li>
          <b>iron:</b> {item.iron !== undefined ? item.iron : "-"}
        </li>
        <li>
          <b>netcarbs:</b> {item.netcarbs !== undefined ? item.netcarbs : "-"}
        </li>
        <li>
          <b>protein:</b> {item.protein !== undefined ? item.protein : "-"}
        </li>
        <li>
          <b>carbs:</b> {item.carbs !== undefined ? item.carbs : "-"}
        </li>
        <li>
          <b>fat:</b> {item.fat !== undefined ? item.fat : "-"}
        </li>
        <li>
          <b>dha:</b> {item.dha !== undefined ? item.dha : "-"}
        </li>
        <li>
          <b>dpa:</b> {item.dpa !== undefined ? item.dpa : "-"}
        </li>
        <li>
          <b>epa:</b> {item.epa !== undefined ? item.epa : "-"}
        </li>
        <li>
          <b>ala:</b> {item.ala !== undefined ? item.ala : "-"}
        </li>
        <li>
          <b>omega3:</b> {item.omega3 !== undefined ? item.omega3 : "-"}
        </li>
      </ul>
    );
  } else {
    return null;
  }
}

export default NutrientsPerServing;
