import { gql } from "@apollo/client";

const UPDATE_TRANLATION_RECIPE = gql`
  mutation RecipeTranslationUpdate($input: RecipeTranslationUpdateInput!) {
    recipeTranslationUpdate(input: $input) {
      recipe {
        id
        name
        ingredients
        ingredientLines
        instructions
      }
    }
  }
`;

export default UPDATE_TRANLATION_RECIPE;
