import React, { useState, Fragment } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { navigate } from "@reach/router";

import RECIPE_DETAIL from "../querys/recipeDetail";
import UPDATE_RECIPE_NUTRIENTS_PER_SERVING from "../mutations/updateRecipeNutrientsPerServing";

import {
  EuiProgress,
  EuiTitle,
  EuiSpacer,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCallOut,
  EuiButton,
  EuiFormRow,
  EuiForm,
  EuiBreadcrumbs,
  EuiGlobalToastList,
} from "@elastic/eui";

function NutrientRowFieldText({ list, i, item, onChangeList }) {
  return (
    <EuiFormRow
      label={`${item.key}`}
      helpText={item.unit}
      display="columnCompressed"
    >
      <EuiFieldText
        key={i}
        name={item.name}
        value={item.value}
        placeholder=""
        compressed={true}
        onChange={(e) => {
          list[i].value = e.target.value;
          onChangeList([...list]);
        }}
      />
    </EuiFormRow>
  );
}

function NutrientsEdit({ list, setList }) {
  const lines = list.map((item, i) => (
    <NutrientRowFieldText
      list={list}
      i={i}
      item={item}
      onChangeList={setList}
      key={`nutrientRow-${i}`}
    />
  ));

  return <Fragment>{lines}</Fragment>;
}

function EditNutrients({ recipe }) {
  const _get_unit = (u) => {
    if (u === "calories") {
      return `kcal`;
    }
    const mg = ["cholesterol", "sodium", "potassium"];
    return mg.includes(u) ? `miligrams` : `grams`;
  };

  const _build_nutrients = (nutrientsPerServing) => {
    const simpleNutrients = [
      "calories",
      "carbs",
      "sugar",
      "fiber",
      "protein",
      "fat",
      "saturatedFat",
      "transFat",
      "cholesterol",
      "sodium",
      "potassium",
    ];

    return Object.keys(nutrientsPerServing)
      .map((i) => ({
        key: i,
        name: i,
        value: nutrientsPerServing[i],
        unit: _get_unit(i),
      }))
      .filter((n) => simpleNutrients.includes(n.name));
  };

  const [numberOfServings, setNumberOfServings] = useState(
    recipe.numberOfServings
  );
  const [weightInGrams, setWeightInGrams] = useState(recipe.weightInGrams);
  const [nutrients, setNutrients] = useState(
    _build_nutrients(recipe.nutrientsPerServing)
  );
  const [toasts, setToasts] = useState([]);

  const [
    update,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_RECIPE_NUTRIENTS_PER_SERVING, {
    errorPolicy: "all",
    onCompleted(data) {
      addToastHandler();
    },
    onError(error) {
      console.log(error);
    },
  });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  const _update = () => {
    let variables = {
      id: recipe.id,
      nutrients: nutrients.map(({ key, value }) => ({
        key,
        value,
      })),
    };
    if (recipe.weightInGrams !== weightInGrams) variables.weightInGrams = weightInGrams;
    update({ variables: variables });
  };

  const addToastHandler = () => {
    const toast = {
      title: "Recipe Updated",
      color: "success",
      iconType: "check",
    };
    setToasts(toasts.concat(toast));
  };

  const removeToast = (removedToast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  return (
    <Fragment>
      <EuiTitle size="l">
        <h1>{recipe.name}</h1>
      </EuiTitle>
      <EuiSpacer />
      <EuiCallOut title="Proceed with caution!" color="warning" iconType="help">
        <p>
          Updating this will override annotations from the ILP(Ingredient Line
          Parser).
        </p>
      </EuiCallOut>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiForm component="form">
            <EuiFormRow
              label="Number of servings"
              helpText="Total servings by recipe"
            >
              <EuiFieldText
                name="numberOfServings"
                value={numberOfServings}
                onChange={(e) => setNumberOfServings(e.target.value)}
                disabled
              />
            </EuiFormRow>

            <EuiFormRow
              label="Weight in Grams"
              helpText="Total recipe weight in grams"
            >
              <EuiFieldText
                name="weightInGrams"
                value={weightInGrams}
                onChange={(e) => setWeightInGrams(e.target.value)}
              />
            </EuiFormRow>

            <EuiSpacer size="xxl" />

            <NutrientsEdit
              list={nutrients}
              setList={setNutrients}
              weightInGrams={weightInGrams}
              numberOfServings={numberOfServings}
            />
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiButton
        type="submit"
        fill
        onClick={_update}
        isLoading={mutationLoading}
      >
        Save
      </EuiButton>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />{" "}
    </Fragment>
  );
}

export default function EditRecipeNutrientsPerServing({ recipeId }) {
  const id = btoa(`Recipe:${recipeId}`);
  const breadcrumbs = [
    {
      text: "Recipes",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes`);
      },
    },
    {
      text: `${recipeId}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes/${recipeId}`);
      },
    },
    {
      text: "Edit Nutrients By Serving",
    },
  ];

  const { loading, error, data } = useQuery(RECIPE_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return (
    <>
      <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
      <EuiSpacer />
      <EditNutrients recipe={data.recipe} />
    </>
  );
}
