import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  useReactFlow,
  Panel,
  addEdge,
  MiniMap,
  Controls,
} from "reactflow";
import { gql, useMutation } from "@apollo/client";
import { EuiButton } from "@elastic/eui";
import { nodeQuestionWidth, nodeQuestionHeight } from "./Helpers";
import NodeFlyout from "./NodeFlyout";

const QUESTION_CREATE = gql`
  mutation questionCreate($input: QuestionCreateInput!) {
    questionCreate(input: $input) {
      success
      question {
        id
      }
    }
  }
`;

const EDGE_CONNECT = gql`
  mutation edgeConnect($input: EdgeConnectInput!) {
    edgeConnect(input: $input) {
      success
    }
  }
`;

const EDGE_DELETE = gql`
  mutation edgeDelete($input: EdgeDeleteInput!) {
    edgeDelete(input: $input) {
      success
    }
  }
`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function Flow({
  name,
  assessmentId,
  initialNodes,
  initialEdges,
}) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [questionCreate] = useMutation(QUESTION_CREATE);
  const [edgeConnect] = useMutation(EDGE_CONNECT);
  const [edgeDelete] = useMutation(EDGE_DELETE);
  const [selected, setSelected] = useState(null);
  const [isFlyoutVisible, toggleIsFlyoutVisible] = useState(false);
  const reactFlowInstance = useReactFlow();

  const prevEdges = usePrevious(edges);

  useEffect(() => {
    if (prevEdges) {
      const deletedEdges = prevEdges.filter(
        (prevEdge) => !edges.some((edge) => edge.id === prevEdge.id)
      );

      deletedEdges.forEach(async (deletedEdge) => {
        const input = {
          sourceType: deletedEdge.data.sourceType,
          sourceId: deletedEdge.source,
        };

        try {
          const response = await edgeDelete({ variables: { input } });
          if (!response.data.edgeDelete.success) {
            console.error("Failed to delete edge");
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }, [edges, edgeDelete]);

  const onNodeClick = (event, node) => {
    setSelected(node);
  };

  useEffect(() => {
    if (selected) {
      toggleIsFlyoutVisible(!isFlyoutVisible);
    }
  }, [selected]);

  const onConnect = useCallback(async (params) => {
    const source = reactFlowInstance.getNode(params.source);
    const input = {
      sourceType: source.data.nodeType,
      source: source.id,
      target: params.target,
    };

    try {
      const response = await edgeConnect({ variables: { input } });
      if (response.data.edgeConnect.success) {
        setEdges((els) => addEdge(params, els));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const addQuestion = useCallback(async () => {
    const input = { assessment: assessmentId };

    try {
      const response = await questionCreate({ variables: { input } });
      const newNode = {
        id: response.data.questionCreate.question.id,
        position: {
          x: Math.random() * 100,
          y: Math.random() * 100,
        },
        data: {
          label: `New question`,
          nodeType: "Question",
        },
        targetPosition: "left",
        sourcePosition: "right",
        style: {
          backgroundColor: "rgba(255, 0, 0, 0.2)",
          width: nodeQuestionWidth,
          height: nodeQuestionHeight,
        },
      };
      reactFlowInstance.addNodes(newNode);
    } catch (err) {
      console.error(err);
    }
  }, [questionCreate]);

  return (
    <>
      {selected && (
        <NodeFlyout
          node={selected}
          isFlyoutVisible={isFlyoutVisible}
          toggleIsFlyoutVisible={toggleIsFlyoutVisible}
          setNodes={setNodes}
        />
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
      >
        <Panel position="top-left">{name}</Panel>
        <MiniMap />
        <Controls />
      </ReactFlow>

      <EuiButton onClick={addQuestion} fill>
        Add question
      </EuiButton>
    </>
  );
}
