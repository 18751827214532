import React, { Fragment } from "react";

import { EuiButtonEmpty } from "@elastic/eui";
import RowPortionFieldText from "./RowPortionFieldText";

function PortionFieldTextList({ list, setList, addText = false }) {
  const addInput = () => {
    list.push({});
    setList([...list]);
  };

  const lines = list.map((item, i) => {
    return <RowPortionFieldText list={list} i={i} item={item} onChangeList={setList}/>;
  });

  return (
    <Fragment>
      {lines}
      <EuiButtonEmpty
        color="primary"
        onClick={() => addInput()}
        iconType="listAdd"
      >
        {addText}
      </EuiButtonEmpty>
    </Fragment>
  );
}

export default PortionFieldTextList;
