import { gql } from "@apollo/client";

const INGREDIENT_SEARCH = gql`
  query ingredientSearch($query: String!) {
    ingredientSearch(query: $query) {
      id
      index
      source {
        description
        name
      }
    }
  }
`;

export default INGREDIENT_SEARCH;
