import React, { Fragment } from "react";
import { Link } from "@reach/router";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiHeader,
  EuiHeaderLinks,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiProgress,
} from "@elastic/eui";

import UserMenu from "../components/UserMenu";

import Recipes from "./recipes";
import Recipe from "./recipe";
import ChangeRecipe from "./changeRecipe";
import EditRecipeTranslation from "./changeRecipeTranslation";
import RecipeCreate from "./recipeCreate";
import RecipeHistory from "./recipeHistory";
import RecipeProgramDebug from "./recipeProgramDebug";
import EditRecipeNutrientsPerServing from "./updateRecipeNutrientsPerServing";
import EditRecipeIngredients from "./editRecipeIngredients";

import Home from "./home";
import MealPlan from "./mealplan";
import MealPlanDebug from "./mealplanDebug";
import Fooddata from "./fooddata";
import Food from "./food";
import ChangeFood from "./changeFood";
import FoodHistory from "./foodHistory";
import GlobalSearch from "./globalSearch";

import Quiz from "./quiz";
import QuizFlow from "./quizFlow";
import AssessmentFlow from "./assessmentFlow";
import CreateAssessment from "./createAssessment";
import Quizzes from "./quizzes";
import IngredientLines from "./ingredienLines";
import ChangeIngredientLine from "./changeIngredientLine";
import Ingredients from "./ingredients";
import FoodLogAssistant from "./foodLogAssistant";
import WellnessGPTLog from "./wellnessGPTLog";

import { Router } from "@reach/router";

const VIEWER = gql`
  {
    viewer {
      username
      firstName
      email
      groups {
        name
      }
      language
    }
  }
`;

const NotFound = () => <p>No page found</p>;

export default function Index() {
  const { loading, error, data } = useQuery(VIEWER, {
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );
  if (error) return `Error! ${error}`;

  const viewerGroups = data.viewer.groups.map((g) => g.name);

  return (
    <Fragment>
      <EuiHeader theme={"dark"}>
        <EuiHeaderLinks>
          <Link to="/" className="euiHeaderLink">
            Home
          </Link>
          {viewerGroups.includes("RECIPES") ? (
            <Link to="recipes" className="euiHeaderLink">
              Recipes
            </Link>
          ) : null}
          {viewerGroups.includes("MEALPLAN") ? (
            <>
            <Link to="mealplan" className="euiHeaderLink">
              Meal Plan
            </Link>
            <Link to="mealplan-debug" className="euiHeaderLink">
              MP Debug
            </Link>
            </>
          ) : null}
          {viewerGroups.includes("FOOD_DATA") ? (
            <Link to="fooddata" className="euiHeaderLink">
              Food Data
            </Link>
          ) : null}
          {viewerGroups.includes("QUIZ") ? (
            <Link to="quiz" className="euiHeaderLink">
              Quiz
            </Link>
          ) : null}
          {viewerGroups.includes("INGREDIENTS") ? (
            <>
              <Link to="ingredient-lines" className="euiHeaderLink">
                Ingredient Lines
              </Link>
              <Link to="ingredients" className="euiHeaderLink">
                Ingredients
              </Link>
            </>
          ) : null}
        </EuiHeaderLinks>
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem>
            <UserMenu viewer={data.viewer} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>

      <EuiPage>
        <EuiPageBody>
          <EuiPageContent>
            <Router>
              <NotFound default />
              <Home path="/" viewer={data.viewer} />

              {viewerGroups.includes("RECIPES") ? (
                <>
                  <Recipes path="recipes" />
                  <RecipeProgramDebug path="recipes/:recipeId/program-debug/:programId" />
                </>
              ) : null}

              {viewerGroups.includes("RECIPES") ||
              viewerGroups.includes("RECIPE_EDITOR") ? (
                <>
                  <Recipe path="recipes/:recipeId" viewer={data.viewer} />
                  <ChangeRecipe path="recipes/:recipeId/change" />
                  <RecipeHistory path="recipes/:recipeId/history" />
                  <RecipeCreate path="recipes/create" />
                  <EditRecipeNutrientsPerServing path="recipes/:recipeId/edit-nutrients-per-serving" />
                  <EditRecipeIngredients path="recipes/:recipeId/edit-ingredients" />
                  <EditRecipeTranslation path="recipes/:recipeId/change/translation" />
                </>
              ) : null}

              {viewerGroups.includes("MEALPLAN") ? (
                <>
                <MealPlan path="mealplan"></MealPlan>
                <MealPlanDebug path="mealplan-debug"></MealPlanDebug>
                </>
              ) : null}

              {viewerGroups.includes("FOOD_DATA") ? (
                <>
                  <Fooddata path="fooddata"></Fooddata>
                  <Food path="fooddata/:foodId"></Food>
                  <ChangeFood path="fooddata/:foodId/change"></ChangeFood>
                  <FoodHistory path="fooddata/:foodId/history" />
                  <GlobalSearch path="search"></GlobalSearch>
                </>
              ) : null}

              {viewerGroups.includes("QUIZ") ? (
                <>
                  <Quizzes path="quiz" />
                  <Quiz path="quiz/:id" />
                  <QuizFlow path="quiz-flow/:id" />
                  <AssessmentFlow path="assessment/:id" />
                  <CreateAssessment path="assessment/:tp/create" />
                  <WellnessGPTLog path="wellness-gpt" />
                </>
              ) : null}

              {viewerGroups.includes("INGREDIENTS") ? (
                <>
                  <IngredientLines path="ingredient-lines" />
                  <ChangeIngredientLine path="ingredient-lines/:id" />
                  <Ingredients path="ingredients" />
                </>
              ) : null}
              {viewerGroups.includes("GPT") ? (
                <>
                  <FoodLogAssistant path="food-log-assistant/:id" />
                </>
              ) : null}
            </Router>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </Fragment>
  );
}
