import React, { useCallback, useState } from "react";

import {
  EuiProgress,
  EuiText,
  EuiMarkdownEditor,
  EuiButton,
  EuiSpacer,
} from "@elastic/eui";

import NoteList from "./NotesList";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const NOTES = gql`
  query notes($objectId: String!) {
    notes(objectId: $objectId) {
      edges {
        node {
          id
          note
          createdAt
          objectId
          user {
            email
          }
        }
      }
    }
  }
`;

const NOTE_CREATE = gql`
  mutation noteCreate($input: NoteCreateInput!) {
    noteCreate(input: $input) {
      success
      note {
        id
        note
        createdAt
        objectId
        user {
          email
        }
      }
    }
  }
`;

export default function Notes({ recipeId }) {
  const { loading, error, data } = useQuery(NOTES, {
    variables: { objectId: recipeId },
    fetchPolicy: "network-only",
  });
  const [value, setValue] = useState("");
  const [messages, setMessages] = useState([]);

  const [noteCreate, { loading: mutationLoading }] = useMutation(NOTE_CREATE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  const _noteCreate = () => {
    noteCreate({
      variables: { input: { note: value, objectId: recipeId } },
      refetchQueries: [
        {
          query: NOTES,
          variables: { objectId: recipeId },
        },
      ],
    });
  };

  const onParse = useCallback((err, { messages }) => {
    setMessages(err ? [err] : messages);
  }, []);

  if (error) return `Error! ${error}`;

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  return (
    <>
      <EuiText size="s">
        <h2>Notes:</h2>
      </EuiText>
      <NoteList notes={data.notes.edges} />

      <EuiMarkdownEditor
        aria-label="EUI markdown editor"
        placeholder="Your markdown here..."
        value={value}
        onChange={setValue}
        height={200}
        onParse={onParse}
        errors={messages}
      />
      <EuiSpacer />
      <div className="eui-textRight">
        <EuiButton
          onClick={_noteCreate}
          isLoading={mutationLoading}
          disabled={mutationLoading}
        >
          Create note
        </EuiButton>
      </div>
    </>
  );
}
