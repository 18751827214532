import React, { useState, Fragment } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiSwitch,
  EuiBreadcrumbs,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiToast,
  EuiFieldNumber,
  EuiImage,
  EuiFilePicker,
  EuiButtonEmpty,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate, Link } from "@reach/router";

import TagsComboBox from "../components/TagsComboBox";
import CuisinesComboBox from "../components/CuisinesComboBox";
import MealTagsComboBox from "../components/MealTagsComboBox";
import AuthorSelect from "../components/AuthorSelect";
import ListFieldText from "../components/ListFieldText";
import UPDATE_RECIPE from "../mutations/updateRecipe";
import UPLOAD_FILE from "../mutations/uploadFile";
import REMOVE_FILE from "../mutations/removeFile";

export default function ChangeRecipeForm({ recipe }) {
  const [displayToast, setDisplayToast] = useState(false);
  const [name, setName] = useState(recipe.name);
  const [author, setAuthor] = useState(recipe.author);
  const [isActive, setIsActive] = useState(
    recipe.isActive ? recipe.isActive : false
  );
  const [isPurchasable, setIsPurchasable] = useState(
    recipe.isPurchasable ? recipe.isPurchasable : false
  );
  const [sourceUrlWorks, setSourceUrlWorks] = useState(
    recipe.sourceUrlWorks ? recipe.sourceUrlWorks : false
  );
  const [sgValidated, setSgValidated] = useState(
    recipe.sgValidated ? recipe.sgValidated : false
  );
  const [notGoodOptionForMealPlan, setNotGoodOptionForMealPlan] = useState(
    recipe.notGoodOptionForMealPlan ? recipe.notGoodOptionForMealPlan : false
  );
  const [tags, setTags] = useState(
    recipe.tags ? recipe.tags.map((tag) => ({ label: tag })) : []
  );
  const [mealTags, setMealTags] = useState(
    recipe.mealTags ? recipe.mealTags.map((tag) => ({ label: tag })) : []
  );
  const [cuisines, setCuisines] = useState(
    recipe.cuisines
      ? recipe.cuisines.map((cuisine) => ({ label: cuisine }))
      : []
  );
  const [ingLines, setIngLines] = useState(
    recipe.ingredientLines ? recipe.ingredientLines : []
  );
  const [instructions, setInstructions] = useState(
    recipe.instructions ? recipe.instructions : []
  );
  const [numberOfServings, setNumberOfServings] = useState(
    recipe.numberOfServings
  );
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(
    recipe.totalTimeInSeconds
  );
  const [totalTime, setTotalTime] = useState(
    recipe.totalTime ? recipe.totalTime : ""
  );
  const [mainImage, setMainImage] = useState(
    recipe.mainImage ? recipe.mainImage : ""
  );

  const [updateRecipe, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_RECIPE, {
      errorPolicy: "all",
      onCompleted(data) {
        setDisplayToast(true);
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    });

  const [
    uploadFile,
    { loading: uploadMutationLoading, error: uploadMutationError },
  ] = useMutation(UPLOAD_FILE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
      if (data.uploadFile?.url) {
        setMainImage(data.uploadFile.url);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const [removeFileMutation] = useMutation(REMOVE_FILE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  const onIsActiveChange = () => {
    setIsActive(!isActive);
  };

  const onIsPurchasableChange = () => {
    setIsPurchasable(!isPurchasable);
  };

  const _updateRecipe = () => {
    let variables = { id: recipe.id };
    if (recipe.name !== name) variables.name = name;
    if (recipe.author !== author) variables.author = author;
    if (recipe.isActive !== isActive) variables.isActive = isActive;
    if (recipe.isPurchasable !== isPurchasable)
      variables.isPurchasable = isPurchasable;
    if (recipe.sourceUrlWorks !== sourceUrlWorks)
      variables.sourceUrlWorks = sourceUrlWorks;
    if (recipe.notGoodOptionForMealPlan !== notGoodOptionForMealPlan)
      variables.notGoodOptionForMealPlan = notGoodOptionForMealPlan;
    if (
      JSON.stringify(recipe.tags) !== JSON.stringify(tags.map((t) => t.label))
    )
      variables.tags = tags.map((t) => t.label);
    if (
      JSON.stringify(recipe.mealTags) !==
      JSON.stringify(mealTags.map((t) => t.label))
    )
      variables.mealTags = mealTags.map((t) => t.label);
    if (
      JSON.stringify(recipe.cuisines) !==
      JSON.stringify(cuisines.map((t) => t.label))
    )
      variables.cuisines = cuisines.map((t) => t.label);
    if (recipe.sgValidated !== sgValidated) variables.sgValidated = sgValidated;
    if (recipe.ingredientLines !== ingLines)
      variables.ingredientLines = ingLines;
    if (recipe.instructions !== instructions)
      variables.instructions = instructions;
    if (recipe.numberOfServings !== numberOfServings)
      variables.numberOfServings = numberOfServings;
    if (recipe.totalTimeInSeconds !== totalTimeInSeconds)
      variables.totalTimeInSeconds = totalTimeInSeconds;
    if (recipe.totalTime !== totalTime) variables.totalTime = totalTime;
    if (recipe.mainImage !== mainImage) variables.mainImage = mainImage;

    updateRecipe({ variables: variables });
  };

  const breadcrumbs = [
    {
      text: "Recipes",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes`);
      },
    },
    {
      text: `${recipe.databaseId}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes/${recipe.databaseId}`);
      },
    },
    {
      text: "Change",
    },
  ];

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Recipe was updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  const onImageChange = (files) => {
    console.log(files);
    if (files.length > 0) {
      uploadFile({ variables: { file: files[0] } });
    }
  };

  const removeFile = () => {
    removeFileMutation({ variables: { file: mainImage } });
    setMainImage("");
  };

  return (
    <Fragment>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer />
      <EuiPageContentHeaderSection>
        <EuiTitle size="s">
          <h1>Change Recipe</h1>
        </EuiTitle>
        <Link to={`/recipes/${recipe.databaseId}/history`}>History</Link>
      </EuiPageContentHeaderSection>
      <EuiSpacer />

      <EuiForm component="form">
        <EuiFormRow label="Name" helpText="The name.">
          <EuiFieldText
            name="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        {!recipe.isFromInternet ? (
          <EuiFormRow label="Author" helpText="Recipe Author.">
            <AuthorSelect item={author} setItem={setAuthor} />
          </EuiFormRow>
        ) : null}

        <EuiFormRow label="Number of Servings">
          <EuiFieldNumber
            placeholder="#"
            value={numberOfServings}
            onChange={(e) => setNumberOfServings(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Total Time In Seconds">
          <EuiFieldNumber
            placeholder=""
            value={totalTimeInSeconds}
            onChange={(e) => setTotalTimeInSeconds(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Total Time" helpText="Recipe total time">
          <EuiFieldText
            name="Total time"
            value={totalTime}
            onChange={(e) => setTotalTime(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Image" helpText="The main image.">
          <Fragment>
            <EuiImage
              size="s"
              hasShadow
              allowFullScreen
              caption=""
              alt={mainImage}
              url={mainImage}
            />
            {mainImage === "" ? (
              <EuiFilePicker
                id="3-doemsodasdf2"
                initialPromptText="Select or drag image"
                onChange={(files) => {
                  onImageChange(files);
                }}
                display={"large"}
                aria-label="Use aria labels when no actual label is in use"
                isLoading={uploadMutationLoading}
                disabled={uploadMutationLoading}
                isInvalid={uploadMutationError}
              />
            ) : (
              <EuiButtonEmpty
                onClick={() => {
                  removeFile();
                }}
                iconType="crossInACircleFilled"
                iconSide="right"
                color={"danger"}
                size="xs"
              >
                Remove Image
              </EuiButtonEmpty>
            )}
          </Fragment>
        </EuiFormRow>

        <EuiFormRow label="Is Active" hasChildLabel={false}>
          <EuiSwitch
            name="isActive"
            label="Is Active?"
            checked={isActive}
            onChange={onIsActiveChange}
          />
        </EuiFormRow>

        <EuiFormRow label="Purchasable" hasChildLabel={false}>
          <EuiSwitch
            name="isPurchasable"
            label="Is Purchasable?"
            checked={isPurchasable}
            onChange={onIsPurchasableChange}
          />
        </EuiFormRow>

        <EuiFormRow label="Suggestic Validated" hasChildLabel={false}>
          <EuiSwitch
            name="SgValidated"
            label="Is suggestic validated?"
            checked={sgValidated}
            onChange={() => setSgValidated(!sgValidated)}
          />
        </EuiFormRow>

        <EuiFormRow
          label="Exclude in Meal Plan"
          hasChildLabel={false}
          helpText={"Activate if we want to exlude this recipe in Meal Plans"}
        >
          <EuiSwitch
            name="SGnotGoodOptionForMealPlan"
            label="Exclude?"
            checked={notGoodOptionForMealPlan}
            onChange={() =>
              setNotGoodOptionForMealPlan(!notGoodOptionForMealPlan)
            }
          />
        </EuiFormRow>

        {recipe.isFromInternet ? (
          <EuiFormRow label="Surce Url work" hasChildLabel={false}>
            <EuiSwitch
              name="sourceUrlWorks"
              label="Does url work?"
              checked={sourceUrlWorks}
              onChange={() => setSourceUrlWorks(!sourceUrlWorks)}
            />
          </EuiFormRow>
        ) : null}

        <EuiFormRow label="Meal Tags">
          <MealTagsComboBox setTags={setMealTags} currentTags={mealTags} />
        </EuiFormRow>

        <EuiFormRow label="Tags">
          <TagsComboBox setTags={setTags} currentTags={tags} />
        </EuiFormRow>

        <EuiFormRow label="Cuisines">
          <CuisinesComboBox
            setCuisines={setCuisines}
            currentCuisines={cuisines}
          />
        </EuiFormRow>

        <EuiFormRow label="Ingredient Lines">
          <ListFieldText
            list={ingLines}
            setList={setIngLines}
            addText="Add another ingredient line"
            parse={true}
          />
        </EuiFormRow>

        <EuiFormRow label="Instructions">
          <ListFieldText
            list={instructions}
            setList={setInstructions}
            addText="Add another instruction"
            textArea={true}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton fill onClick={_updateRecipe} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>

      <EuiSpacer />
      <Toast />
    </Fragment>
  );
}
