import React from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiStat,
  EuiI18nNumber,
} from "@elastic/eui";

import { useQuery } from "@apollo/client";

import RECIPE_QUERY_STATS from "../querys/recipeQueryStats";

function RecipeQueryStats({
  query,
  isActive,
  isPremium,
  isPurchasable,
  sourceUrlWorks,
  hasImage,
  useMatchPhrase,
  caloriesRange,
  proteinRange,
  fatRange,
  carbsRange,
  omega3Range,
  ingredientRange,
  totalTimeRange,
  meals,
  hasKnownIngredients,
  mustIngredients,
  tags,
  courses,
  author,
  source,
  restrictions,
}) {
  const { loading, error, data } = useQuery(RECIPE_QUERY_STATS, {
    variables: {
      query: query,
      isActive: isActive,
      isPremium: isPremium,
      isPurchasable: isPurchasable,
      sourceUrlWorks: sourceUrlWorks,
      hasImage: hasImage,
      useMatchPhrase: useMatchPhrase,
      caloriesRange: caloriesRange,
      proteinRange: proteinRange,
      fatRange: fatRange,
      carbsRange: carbsRange,
      omega3Range: omega3Range,
      ingredientRange: ingredientRange,
      totalTimeRange: totalTimeRange,
      meals: meals
        .filter((meal) => meal.checked === "on")
        .map(function (meal) {
          return meal.key;
        }),
      hasKnownIngredients: hasKnownIngredients,
      mustIngredients: mustIngredients.map(function (ingredient) {
        return ingredient.label;
      }),
      tags: tags.map(function (i) {
        return i.label;
      }),
      courses: courses.map(function (i) {
        return i.label;
      }),
      author: author,
      source: source,
      restrictions: restrictions.map(function (i) {
        return i.label;
      }),
    },
  });

  if (error) return `Error! ${error}`;

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiStat
          title={
            data ? <EuiI18nNumber value={data.recipeQueryStats.count} /> : 0
          }
          description="Total Recipes"
          titleSize="l"
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiStat
          title={
            data ? <EuiI18nNumber value={data.recipeQueryStats.breakfast} /> : 0
          }
          description="Breakfast"
          titleSize="l"
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiStat
          title={
            data ? <EuiI18nNumber value={data.recipeQueryStats.lunch} /> : 0
          }
          description="Lunch"
          titleSize="l"
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiStat
          title={
            data ? <EuiI18nNumber value={data.recipeQueryStats.dinner} /> : 0
          }
          description="Dinner"
          titleSize="l"
          isLoading={loading}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiStat
          title={
            data ? <EuiI18nNumber value={data.recipeQueryStats.snack} /> : 0
          }
          description="Snack"
          titleSize="l"
          isLoading={loading}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default RecipeQueryStats;
