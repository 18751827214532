import React, { useState } from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

//TODO: soport vairable for field aggs
const INGREDIENT_AGGS = gql`
  {
    ingredientLinesAggs(field: "ingredient.keyword") {
      docCount
      key
    }
  }
`;

export default function IngredientLinesAggComboBox({ ingredientsChanged }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { loading, error, data } = useQuery(INGREDIENT_AGGS);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.ingredientLinesAggs.forEach((ing) =>
      options.push({ label: `${ing.key} (${ing.docCount})`, value: ing.key })
    );
  }

  function onChange(ings) {
    setSelectedOptions(ings);
    ingredientsChanged(ings);
  }

  return (
    <EuiComboBox
      placeholder="With ingredients"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      isClearable={true}
      data-test-subj="demoComboBox"
      isLoading={loading}
      fullWidth={true}
    />
  );
}
