import React, {useState}from 'react';

import {
  EuiProgress,
  EuiButtonIcon,
} from '@elastic/eui';

import { useMutation } from '@apollo/client';

import FLAG_AS_NOT_GOOD_OPTION from '../mutations/flagAsNotGoodOption';

function DeactivateRecipe({ recipeId }) {
  const [isActive, setIsActive] = useState(false);
  const [flagAsNotGoodOption, { error, loading }] = useMutation(FLAG_AS_NOT_GOOD_OPTION);

  function toggleIsisActive() {
    setIsActive(!isActive);
  }

  function deactivateRecipe() {
    toggleIsisActive();
    flagAsNotGoodOption({ variables: {recipeId: recipeId } });
  }

  if (loading) return (
    <div>
      <EuiProgress size="xs" color="accent" />
    </div>
  );
  if (error) return `Error! ${error}`;

  return (
    <EuiButtonIcon
      color='danger'
      onClick={deactivateRecipe}
      iconType="crossInACircleFilled"
      aria-label="deactivate"
      isDisabled={isActive}
    />
  );
}

export default DeactivateRecipe;