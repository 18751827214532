import React from "react";

import { EuiSelect } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const PROGRAMS = gql`
{
  programs {
    edges {
      node {
        id
        name
      }
    }
  }
}
`;

export default function ProgramsSelect({value, setItem}) {
  const { loading, error, data } = useQuery(PROGRAMS, {
    variables: { first: 20},
  });

  if (error) return `Error! ${error}`;

  let options = [];

  const onChange = (e) => {
    setItem(e.target.value);
  };

  if (data) {
    data.programs.edges.forEach((a) =>
      options.push({ value: a.node.id, text: a.node.name })
    );
    let optFilter = options.filter((o) => o.value === value);
    if (value !== undefined && optFilter && !optFilter.length) {
      value = undefined;
    }
    return (
      <EuiSelect
        id="programSelectXX1"
        options={options}
        value={value}
        onChange={onChange}
        isLoading={loading}
      />
    );
  }
  return null;
}
