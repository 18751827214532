import { gql } from "@apollo/client";

const UPDATE_USER_LANGUAGE = gql`
mutation updateUserLanguage($language: UserLanguage!){
	updateUserLanguage(language: $language) {
    success
  }
}
`;

export default UPDATE_USER_LANGUAGE