import React from "react";

export default function CreateAssessment({ tp }) {

  return (
    <>
      <p>Create</p>
    </>
  );
}
