import React from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { EuiFlyout, EuiProgress, EuiSpacer } from "@elastic/eui";
import IngredientEditFlyout from "./IngredientEditFlyout";

const INGREDIENT = gql`
  query ingredient($id: ID!) {
    ingredient(id: $id) {
      id
      name
      slug
      alias
      aisle {
        id
        name
      }
      ingredientgroupSet {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default function IngredientFlyout({ close, id, query }) {
  const { loading, error, data } = useQuery(INGREDIENT, {
    variables: { id: id },
  });

  if (error) return `Error! ${error}`;

  if (loading) {
    return (
      <EuiFlyout onClose={close} aria-labelledby="flyoutTitle">
        <EuiSpacer />
        <EuiProgress size="xs" color="accent" />
      </EuiFlyout>
    );
  }

  return (
    <EuiFlyout onClose={close} aria-labelledby="flyoutTitle">
      <IngredientEditFlyout data={data} query={query} close={close} />
    </EuiFlyout>
  );
}
