import React, { Fragment, useState } from "react";

import {
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiButton,
  EuiSpacer,
} from "@elastic/eui";

import MealPlan from "../components/MealPlan";
import RestrictionComboBox from "../components/RestrictionComboBox";
import ProgramsSelect from "../components/ProgramSelect";

export default function MealPlan2() {
  const [calories, setCalories] = useState(1400);
  const [protein, setProtein] = useState(0.25);
  const [carbs, setCarbs] = useState(0.45);
  const [fat, setFat] = useState(0.3);
  const [omega3, setOmega3] = useState(0.02);
  const [days, setDays] = useState(7);
  const [restrictions, setRestrictions] = useState([]);
  const [program, setProgram] = useState("0ad4e77a-8eda-4e9b-ac8e-1c84a2e8bb42");

  const [props, setProps] = useState({
    calories: 1400,
    protein: 0.25,
    carbs: 0.45,
    fat: 0.3,
    omega3: 0.02,
    days: 7,
    restrictions: [],
    program: "0ad4e77a-8eda-4e9b-ac8e-1c84a2e8bb42",
  });

  function generate() {
    setProps({
      calories: calories,
      protein: protein,
      carbs: carbs,
      fat: fat,
      omega3: omega3,
      days: days,
      restrictions: restrictions,
      program: program
    });
  }

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="Total Calories" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={calories}
              onChange={(e) => setCalories(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Carbs" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={carbs}
              onChange={(e) => setCarbs(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Protein" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Fat" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={fat}
              onChange={(e) => setFat(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Omega3" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={omega3}
              onChange={(e) => setOmega3(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Number of days" helpText="">
            <EuiFieldNumber
              placeholder=""
              value={days}
              onChange={(e) => setDays(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButton onClick={generate}>Generate</EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup style={{ maxWidth: 600 }}>
        <EuiFlexItem>
          <EuiFormRow label="Program">
            <ProgramsSelect
              items={program}
              setItem={setProgram}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Restrictions">
            <RestrictionComboBox
              items={restrictions}
              setItem={setRestrictions}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="xl" />

      <MealPlan
        calories={props.calories}
        protein={props.protein}
        carbs={props.carbs}
        fat={props.fat}
        omega3={props.omega3}
        days={props.days}
        restrictions={props.restrictions}
        program={props.program}
      />
    </Fragment>
  );
}
