import React from "react";
import { EuiButton } from "@elastic/eui";
import { useLazyQuery } from "@apollo/client";
import CLASSIFY_AISLE from "../querys/classifyAisle";

export default function ClassifyAisleButton({ ingredient }) {
  const [classify, { loading, data }] = useLazyQuery(CLASSIFY_AISLE);

  return (
    <EuiButton
      size="s"
      isLoading={loading}
      iconType="inspect"
      onClick={() => classify({ variables: { ingredient: ingredient } })}
      disabled={data ? true : false}
    >
      {data ? data.classifyAisle : `classify with GPT-3`}
    </EuiButton>
  );
}
