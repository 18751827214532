import { gql } from "@apollo/client";

const RECIPE_PROGRAM_DEBUG = gql`
  query recipeProgramDebug($recipeId: String!, $programId: String!) {
    recipeProgramDebug(recipeId: $recipeId, programId: $programId) {
      program {
        name
      }
      recipe {
        name
        isActive
        hasKnownIngredients
        knownIngredients {
          known
          unknown
        }
      }
      debug {
        ingredients
        cpc
        ingredientGroup
        actionType
      }
    }
  }
`;

export default RECIPE_PROGRAM_DEBUG;
