import { gql } from "@apollo/client";

const RECIPE_TAG_CREATE = gql`
  mutation CreateRecipe($input: RecipeTagCreateInput!) {
    recipeTagCreate(input: $input) {
      success
      recipeTag {
        id
        displayName
        termName
      }
    }
  }
`;

export default RECIPE_TAG_CREATE;
