import { gql } from "@apollo/client";

const RECIPE_DETAIL = gql`
  query recipe($id: ID!) {
    recipe(id: $id) {
      id
      databaseId
      name
      author
      mainImage
      squareImage
      isActive
      isPremium
      sgValidated
      servingWeight
      courses
      mealTags
      text
      ingredientLines
      ingredients
      weightInGrams
      totalTimeInSeconds
      totalTime
      ingredientsCount
      tags
      instructions
      numberOfServings
      isPurchasable
      hasKnownIngredients
      sourceUrlWorks
      isFromInternet
      notGoodOptionForMealPlan
      attribution
      cuisines
      knownIngredients {
        known
        unknown
      }
      nutrientsPerServing {
        calories
        choline
        sugar
        fiber
        saturatedFat
        monounsaturatedFat
        polyunsaturatedFat
        transFat
        cholesterol
        sodium
        potassium
        vitaminA
        vitaminC
        vitaminB6
        vitaminB12
        vitaminB7
        vitaminD
        vitaminE
        calcium
        iron
        netcarbs
        protein
        carbs
        fat
        dha
        dpa
        epa
        ala
        omega3
      }
      source {
        siteUrl
        recipeUrl
      }
      nutritionalInfo {
        calories
        choline
        protein
        carbs
        fat
        sugar
        fiber
        saturatedFat
        monounsaturatedFat
        polyunsaturatedFat
        transFat
        cholesterol
        sodium
        potassium
        vitaminA
        vitaminC
        vitaminB7
        calcium
        iron
        netcarbs
      }
      rawNutrients {
        calories
        protein
        carbs
        fat
        sugar
        fiber
        saturatedFat
        monounsaturatedFat
        polyunsaturatedFat
        transFat
        cholesterol
        sodium
        potassium
        vitaminA
        vitaminC
        calcium
        iron
      }
      caloriesPerServing {
        protein
        carbs
        fat
        dha
        dpa
        epa
        ala
      }
      nutrients {
        key
        value
        name
      }
      externalId
      indexedAt
      updatedAt
    }
  }
`;

export default RECIPE_DETAIL;
