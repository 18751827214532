import React, { useState } from "react";
import {
  EuiFlyout,
  EuiSpacer,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiForm,
  EuiFormRow,
  EuiTextArea,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiFieldText,
} from "@elastic/eui";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import useForm from "../hooks/useForm";
import AislesSelect from "./AisleSelect";
import INGREDIENTS from "../querys/ingredients";

const CREATE_INGREDIENT = gql`
  mutation createIngredient($name: String!, $aisle: String!, $alias: [String]) {
    createIngredient(name: $name, aisle: $aisle, alias: $alias) {
      success
      id
      message
    }
  }
`;

const validate = (values) => {
  let errors = {};

  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.aisle) {
    errors.aisle = "Aisle is required";
  }
  return errors;
};

export default function IngredientCreateFlyout({ close, query }) {
  const [alias, setAlias] = useState("");

  const { values, errors, handleChange, handleSubmit } = useForm(
    save,
    validate
  );

  const [createIngredient, { loading, error }] = useMutation(
    CREATE_INGREDIENT,
    {
      errorPolicy: "all",
      onCompleted(data) {
        console.log(data);
        close();
      },
      onError() {
        //setShowErrors(true);
      },
    }
  );

  function save() {
    let variables = {
      name: values.name,
      aisle: values.aisle,
      alias: alias ? alias.split(",") : [],
    };
    createIngredient({
      variables: variables,
      refetchQueries: [
        {
          query: INGREDIENTS,
          variables: { query: query },
        },
      ],
    });
  }

  let showErrors;
  if (errors.name || errors.aisle || error) {
    showErrors = true;
  }

  return (
    <EuiFlyout onClose={close} aria-labelledby="flyoutTitle">
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 id="flyoutTitle">Create Ingredient</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm component="form" isInvalid={showErrors}>
          <EuiFormRow
            label="Name"
            helpText="Ingredient common name"
            isInvalid={errors.name ? true : false}
            error={[errors.name]}
          >
            <EuiFieldText
              placeholder=""
              aria-label=""
              value={values.name || ""}
              name={"name"}
              onChange={handleChange}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Alias"
            helpText="Other representation of the same ingredient, each alias should be separated by comma"
          >
            <EuiTextArea
              placeholder=""
              aria-label=""
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow
            label="Aisle"
            helpText="Aisle used in shopping list"
            isInvalid={errors.aisle ? true : false}
            error={[errors.aisle]}
          >
            <AislesSelect
              value={values.aisle}
              onChange={handleChange}
              name={"aisle"}
            />
          </EuiFormRow>

          <EuiSpacer />

          <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            responsive={false}
            wrap
          >
            <EuiFlexItem grow={false}>
              <EuiButton
                fill
                onClick={handleSubmit}
                isLoading={loading}
                disabled={loading}
              >
                Create Ingredient
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}
