import React, { useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiProgress,
  EuiMarkdownFormat,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
} from "@elastic/eui";

const TRANSLATE = gql`
  query translateRecipe($id: ID!, $language: String) {
    translateRecipe(id: $id, language: $language)
  }
`;

const RecipeTranslate = ({ recipeId }) => {
  const [translate, { loading, data, error }] = useLazyQuery(TRANSLATE);
  const [isPopoverOpen, setPopover] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = (language) => {
    closePopover();
    setIsModalVisible(true);
    translate({ variables: { id: recipeId, language: language } });
  };

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const items = [
    <EuiContextMenuItem
      key="Spanish"
      icon="quote"
      onClick={() => showModal("Spanish")}
    >
      Spanish
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="Dutch"
      icon="quote"
      onClick={() => showModal("Dutch")}
    >
      Dutch
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="Arabic"
      icon="quote"
      onClick={() => showModal("Arabic")}
    >
      Arabic
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="English"
      icon="quote"
      onClick={() => showModal("English (United Kingdom)")}
    >
      English (United Kingdom)
    </EuiContextMenuItem>,
  ];

  const button = (
    <EuiButton iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
      Translate
    </EuiButton>
  );

  let modal;

  if (isModalVisible) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Translation:</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiSpacer />
          {loading && <EuiProgress size="xs" color="accent" />}
          {data && (
            <EuiMarkdownFormat>
              {data.translateRecipe.choices[0].message.content}
            </EuiMarkdownFormat>
          )}
          {error && error}
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <div>
      <EuiPopover
        id="euipopover-1000"
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize="none"
        anchorPosition="downLeft"
      >
        <EuiContextMenuPanel size="m" items={items} />
      </EuiPopover>
      {modal}
    </div>
  );
};

export default RecipeTranslate;
