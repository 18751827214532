import { gql } from "@apollo/client";

const ASSISTANT_MESSAGE = gql`
  query assistantMessage($id: ID!) {
    assistantMessage(id: $id) {
      id
      message
      messageType
      createdAt
      mealTime
      isLogged
      metadata
      gptingredientSet {
        edges {
          node {
            id
            name
            grams
            matchId
            quantity
            matchType
            unit
            matchMetadata
          }
        }
      }
    }
  }
`;

export default ASSISTANT_MESSAGE;
