import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "@reach/router";

import RECIPE_DETAIL from "../querys/recipeDetail";
import MacrosProgress from "../components/MacrosProgress";
import ParsedIngredientLines from "../components/ParsedIngredientLines";
import NutrientsPerServing from "../components/NutrientsPerServing";
import RecipeCloneButton from "../components/RecipeCloneButton";
import RecipeTranslate from "../components/RecipeTranslate";
import RecipeAnnotateNutrientsButton from "../components/RecipeAnnotateNutrientsButton";
import Notes from "../components/Notes";

import {
  EuiProgress,
  EuiTitle,
  EuiSpacer,
  EuiImage,
  EuiHealth,
  EuiDescriptionList,
  EuiFlexItem,
  EuiFlexGroup,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiButton,
  EuiI18nNumber,
  EuiText,
} from "@elastic/eui";

function KnownIngredients({ known, unknown }) {
  const knownItems = known.map((item, i) => (
    <li key={`knownItem-${i}`}>
      <EuiText color="secondary">{item}</EuiText>
    </li>
  ));
  const unknownItems = unknown.map((item, i) => (
    <li key={`unknownItem-${i}`}>
      <EuiText color="danger">{item}</EuiText>
    </li>
  ));
  return (
    <ul>
      {knownItems}
      {unknownItems}
    </ul>
  );
}

function Recipe({ recipeId, viewer }) {
  const id = btoa(`Recipe:${recipeId}`);
  const { loading, error, data } = useQuery(RECIPE_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const courses = data.recipe.courses
    ? data.recipe.courses.map((course, i) => (
        <li key={`course-${i}`}>{course}</li>
      ))
    : [];

  const mealTags = data.recipe.mealTags
    ? data.recipe.mealTags.map((mealTag, i) => (
        <li key={`malTag-${i}`}>{mealTag}</li>
      ))
    : [];

  const tags = data.recipe.tags
    ? data.recipe.tags.map((tag, i) => <li key={`tag-${i}`}>{tag}</li>)
    : [];

  const cuisines = data.recipe.cuisines
    ? data.recipe.cuisines.map((tag, i) => <li key={`cuisine-${i}`}>{tag}</li>)
    : [];

  let links;

  if (viewer.language !== "EN") {
    links = (
      <Link to={`/recipes/${recipeId}/change/translation`}>
        <EuiButton fill>Edit Translation</EuiButton>
      </Link>
    );
  } else {
    links = (
      <>
        <Link to={`/recipes/${recipeId}/change`}>
          <EuiButton fill>Edit</EuiButton>
        </Link>{" "}
        <Link to={`/recipes/${recipeId}/edit-nutrients-per-serving`}>
          <EuiButton color="secondary">Edit Nutrients</EuiButton>
        </Link>
        <Link to={`/recipes/${recipeId}/edit-ingredients`}>
          <EuiButton color="secondary">Edit Ingredients</EuiButton>
        </Link>
        <RecipeCloneButton recipeId={recipeId} />
        <RecipeTranslate recipeId={id} />
        {!data.recipe.attribution && <RecipeAnnotateNutrientsButton id={id} />}
      </>
    );
  }

  return (
    <Fragment>
      <EuiTitle size="l">
        <h1>{data.recipe.name}</h1>
      </EuiTitle>
      <EuiTitle size="xs">
        <h4>By {data.recipe.author}</h4>
      </EuiTitle>
      <a href={data.recipe.source ? data.recipe.source.recipeUrl : ""}>
        {data.recipe.source ? data.recipe.source.recipeUrl : ""}
      </a>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiDescriptionList>
            {data.recipe.externalId ? (
              <div>
                <EuiDescriptionListTitle>External Id</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>
                  {data.recipe.externalId}
                </EuiDescriptionListDescription>
              </div>
            ) : null}

            <EuiDescriptionListTitle>Minutes</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.recipe.totalTime}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>
              Total Time In Seconds
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.recipe.totalTimeInSeconds}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Is Active</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiHealth
                color={data.recipe.isActive ? "success" : "danger"}
              ></EuiHealth>
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>
              Has known ingredients
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiHealth
                color={data.recipe.hasKnownIngredients ? "success" : "danger"}
              ></EuiHealth>
            </EuiDescriptionListDescription>

            {data.recipe.isFromInternet ? (
              <>
                <EuiDescriptionListTitle>
                  Source Url Works
                </EuiDescriptionListTitle>
                <EuiDescriptionListDescription>
                  <EuiHealth
                    color={data.recipe.sourceUrlWorks ? "success" : "danger"}
                  ></EuiHealth>
                </EuiDescriptionListDescription>
              </>
            ) : null}

            <EuiDescriptionListTitle>Ingredients:</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <KnownIngredients
                known={data.recipe.knownIngredients.known}
                unknown={data.recipe.knownIngredients.unknown}
              />
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Courses</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {courses}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Meal Tags</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {mealTags}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Tags</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {tags}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Cuisines</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {cuisines}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle># Favorites</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.recipe.favoritesCount}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Indexed At</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.recipe.indexedAt}
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Updated At</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.recipe.updatedAt}
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiDescriptionList>
            <EuiDescriptionListTitle>
              {data.recipe.numberOfServings
                ? data.recipe.numberOfServings
                : "-"}{" "}
              Serving -{" "}
              {<EuiI18nNumber value={Math.round(data.recipe.servingWeight)} />}{" "}
              g -{" "}
              {data.recipe.nutrientsPerServing
                ? Math.round(data.recipe.nutrientsPerServing.calories)
                : "-"}{" "}
              kcal
            </EuiDescriptionListTitle>
            <EuiSpacer />

            {data.recipe.caloriesPerServing ? (
              <EuiDescriptionListDescription>
                <MacrosProgress recipe={data.recipe} />
              </EuiDescriptionListDescription>
            ) : (
              ""
            )}

            <EuiDescriptionListTitle>
              Nutrients per serving
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <NutrientsPerServing recipe={data.recipe} />
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiImage
            size="l"
            hasShadow
            allowFullScreen
            caption={data.recipe.name}
            alt={data.recipe.name}
            url={data.recipe.mainImage}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiTitle size="xs">
        <h3>Ingredient lines</h3>
      </EuiTitle>
      {data.recipe.ingredientLines ? (
        <ParsedIngredientLines ingredientLines={data.recipe.ingredientLines} />
      ) : null}
      {id}
      <EuiSpacer />

      {links}
      <EuiSpacer />
      <Notes recipeId={recipeId} />
    </Fragment>
  );
}

export default Recipe;
