import React from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { EuiBasicTable, EuiProgress, EuiButtonEmpty } from "@elastic/eui";

const QUIZZES = gql`
  {
    quizzes {
      id
      name
      createdAt
    }
  }
`;

export default function Quizzes() {
  const { loading, error, data } = useQuery(QUIZZES, {
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const columns = [
    {
      field: "id",
      name: "Id",
    },
    {
      field: "name",
      name: "Name",
    },
    {
      field: "id",
      name: "JSON URL",
      render: (id) => {
        return (
          <EuiButtonEmpty href={`https://production.suggestic.com/api/v2/quiz/${id}`} iconType="document">
            {`https://production.suggestic.com/api/v2/quiz/${id}`}
          </EuiButtonEmpty>
        );
      },
    },
    {
      field: "id",
      name: "Edit",
      render: (id) => {
        return (
          <EuiButtonEmpty href={`/quiz/${id}`} iconType="indexEdit">
            Edit
          </EuiButtonEmpty>
        );
      },
    },
    {
      field: "id",
      name: "Flow",
      render: (id) => {
        return (
          <EuiButtonEmpty href={`/quiz-flow/${id}`} iconType="timeline">
            Flow
          </EuiButtonEmpty>
        );
      },
    },
  ];

  const getRowProps = (item) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${id}`),
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  return (
    <EuiBasicTable
      items={data.quizzes}
      rowHeader="firstName"
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps}
    />
  );
}
