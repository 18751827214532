import React, { useState, Fragment } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import {
  EuiCodeEditor,
  EuiProgress,
  EuiButton,
  EuiTitle,
  EuiBadge,
  EuiSpacer,
} from "@elastic/eui";

import "brace/mode/less";
import "brace/theme/github";

const QUIZ = gql`
  query quiz($id: ID!) {
    quiz(id: $id) {
      name
      data
    }
  }
`;

const UPDATE_QUIZ = gql`
  mutation updateQuiz($id: ID!, $data: String!) {
    updateQuiz(id: $id, data: $data) {
      success
    }
  }
`;

function QuizEdit({ id, quiz }) {
  const [value, updateValue] = useState(quiz.data);
  const [isValid, updateIsValid] = useState(true);
  const [
    updateQuiz,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_QUIZ);

  if (mutationError) return `Error! ${mutationError}`;

  const onChange = (value) => {
    updateIsValid(isJsonString(value));
    updateValue(value);
  };

  const update = () => {
    updateQuiz({ variables: { id: id, data: value } });
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <Fragment>
      <EuiTitle size="m">
        <h2>
          Edit {quiz.name} Quiz{" "}
          {isValid ? (
            <EuiBadge color="secondary">Valid JSON </EuiBadge>
          ) : (
            <EuiBadge color="warning">Invalid JSON</EuiBadge>
          )}
        </h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiCodeEditor
        mode="less"
        theme="github"
        width="100%"
        value={value}
        onChange={onChange}
        setOptions={{ fontSize: "12px" }}
        aria-label="Quiz editor"
      />
      <EuiSpacer />
      <EuiButton
        onClick={update}
        isLoading={mutationLoading}
        isDisabled={mutationLoading || !isValid}
      >
        Save
      </EuiButton>
    </Fragment>
  );
}

export default function Quiz({ id }) {
  const { loading, error, data } = useQuery(QUIZ, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return <QuizEdit id={id} quiz={data.quiz} />;
}
