import React from "react";

import {
  EuiBasicTable,
  EuiHealth,
  EuiButtonIcon,
} from "@elastic/eui";


export default function IngredientLinesResults({ data }) {
  const columns = [
    {
      field: "node.ingredientLine.original",
      name: "IngredientLine",
    },
    {
      field: "node.quantity",
      name: "Quantity",
      width: "100px",
      render: (quantity) => {
        return quantity ? Math.round(quantity.numeric) : "";
      },
    },
    {
      field: "node.unit.original",
      name: "Unit",
      width: "100px"
    },
    {
      field: "node.ingredient",
      name: "Ingredient",
    },
    {
      field: "node.walmart.department",
      name: "Department",
    },
    {
      field: "node.walmart.aisle",
      name: "Aisle",
    },
    {
      field: "node.usda.usdaName",
      name: "USDA",
      truncateText: true
    },
    {
      field: "node.isKnownIngredient",
      name: "Is Known?",
      dataType: "boolean",
      render: (isKnownIngredient) => {
        const color = isKnownIngredient ? "success" : "danger";
        return <EuiHealth color={color}></EuiHealth>;
      },
    },
    {
      field: "node.id",
      name: "Detail",
      render: (id) => {
        return (
          <EuiButtonIcon
            href={`/ingredient-lines/${id}`}
            iconType="bullseye"
          ></EuiButtonIcon>
        );
      },
    },
  ];

  const getRowProps = (item) => {
    const { databaseId } = item.node;
    return {
      "data-test-subj": `row-${databaseId}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${databaseId}`),
    };
  };

  const getCellProps = (item, column) => {
    const { databaseId } = item.node;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${databaseId}-${field}`,
      textOnly: true,
    };
  };

  return (
    <EuiBasicTable
      items={data.ingredientLines.edges}
      rowHeader="rowHeaderXX"
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps}
    />
  );
}
