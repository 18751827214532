import { gql } from "@apollo/client";

const RECIPE_QUERY_STATS = gql`
  query recipeQueryStats(
    $query: String
    $isActive: Boolean
    $isPremium: Boolean
    $isPurchasable: Boolean
    $sourceUrlWorks: Boolean
    $hasImage: Boolean
    $useMatchPhrase: Boolean
    $caloriesRange: [Float]
    $proteinRange: [Float]
    $fatRange: [Float]
    $carbsRange: [Float]
    $omega3Range: [Float]
    $ingredientRange: [Int]
    $totalTimeRange: [Int]
    $meals: [String]
    $recommendedCourses: Boolean
    $hasKnownIngredients: Boolean
    $mustIngredients: [String]
    $tags: [String]
    $courses: [String]
    $author: String
    $source: String
    $restrictions: [String]
  ) {
    recipeQueryStats(
      query: $query
      isActive: $isActive
      isPremium: $isPremium
      isPurchasable: $isPurchasable
      sourceUrlWorks: $sourceUrlWorks
      hasImage: $hasImage
      useMatchPhrase: $useMatchPhrase
      caloriesRange: $caloriesRange
      proteinRange: $proteinRange
      fatRange: $fatRange
      carbsRange: $carbsRange
      omega3Range: $omega3Range
      ingredientRange: $ingredientRange
      totalTimeRange: $totalTimeRange
      meals: $meals
      recommendedCourses: $recommendedCourses
      hasKnownIngredients: $hasKnownIngredients
      mustIngredients: $mustIngredients
      tags: $tags
      courses: $courses
      author: $author
      source: $source
      restrictions: $restrictions
    ) {
      count
      breakfast
      lunch
      dinner
      snack
    }
  }
`;

export default RECIPE_QUERY_STATS;
