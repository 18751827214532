import React, { Fragment, useState } from "react";

import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiButton,
  EuiSpacer,
  EuiProgress,
} from "@elastic/eui";

import ReactJson from "react-json-view";
import MealPlanDebugMode from "../components/MealPlanDebugMode";
import { useLazyQuery, gql } from "@apollo/client";

const MEAL_PLAN_DEBUG = gql`
  query mealPlanDebug($userId: String!) {
    mealPlanDebug(userId: $userId) {
      meals {
        id
        name
        protein
        calories
        fat
        omega3
        carbs
        mealTag
        servingWeight
        maxNumOfServings
        numberOfServings
        ingredients
        mainImage
      }
      debug
      program {
        id
        name
      }
      restrictions {
        slugname
      }
      filters {
        key
        counts
      }
    }
  }
`;

function Debug({ data }) {
  return (
    <>
      <p>Program: {data.mealPlanDebug.program.name}</p>
      <EuiSpacer size="xl" />

      <p>Restrictions:</p>
      <ReactJson src={data.mealPlanDebug.restrictions} collapsed />
      <EuiSpacer size="xl" />

      <p>Debug:</p>
      <ReactJson src={data.mealPlanDebug.debug} collapsed />
      <EuiSpacer size="xl" />

      <p>Filters:</p>
      <ReactJson src={data.mealPlanDebug.filters} collapsed />
    </>
  );
}

export default function MealPlanDebug() {
  const [userId, setUserId] = useState("87d07b78-ead1-4411-ac84-df1fb2f622ed");

  const debug = () => {
    if (userId !== "") {
      loadQuery();
    }
  };

  const [loadQuery, { called, loading, data }] = useLazyQuery(MEAL_PLAN_DEBUG, {
    variables: { userId: userId },
  });

  if (called && loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="User ID" helpText="">
            <EuiFieldText
              placeholder=""
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              aria-label=""
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButton onClick={debug}>Debug</EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      {data?.mealPlanDebug?.debug && <Debug data={data} />}
      <EuiSpacer size="xl" />
      {data?.mealPlanDebug?.meals && <MealPlanDebugMode data={data} />}
    </Fragment>
  );
}
