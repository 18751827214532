import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

import { EuiProgress } from "@elastic/eui";

import { ReactFlowProvider, MarkerType } from "reactflow";
import "reactflow/dist/style.css";
import Flow from "../components/Quiz/Flow";
import NodeFlyout from "../components/Quiz/NodeFlyout";
import { generateUniqueId } from "../utils";
import {
  getLayoutedElements,
  nodeQuestionWidth,
  nodeQuestionHeight,
} from "../components/Quiz/Helpers";

const QUIZ = gql`
  query quiz($id: ID!) {
    quiz(id: $id) {
      name
      data
    }
  }
`;

export default function QuizFlow({ id }) {
  const [selected, setSelected] = useState(null);
  const [isFlyoutVisible, toggleIsFlyoutVisible] = useState(false);
  const { loading, error, data } = useQuery(QUIZ, {
    variables: { id: id },
  });

  useEffect(() => {
    if (selected) {
      toggleIsFlyoutVisible(!isFlyoutVisible);
    }
  }, [selected, isFlyoutVisible]);

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const quiz = JSON.parse(data.quiz.data);

  const getAnswer = (answerId) => {
    const answer = quiz.answers.find((obj) => obj.answer_id === answerId);
    if (answer) {
      return answer.next_id;
    }
    return null;
  };

  const getQuestion = (questionId) => {
    const question = quiz.questions.find(
      (obj) => obj.question_id === questionId
    );
    if (question) {
      return question.question_id;
    }
    return null;
  };

  const initialEdges = [];

  const initialNodes = quiz.questions.map((item) => {
    if (item.skip_answer) {
      initialEdges.push({
        id: generateUniqueId(),
        source: item.question_id,
        target: getAnswer(item.skip_answer),
        label: "Skip",
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        style: { stroke: "red" },
      });
    }
    return {
      id: item.question_id,
      position: { x: 0, y: 0 },
      data: { label: item.title, nodeType: "Question" },
      style: {
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        width: nodeQuestionWidth,
        height: nodeQuestionHeight,
      },
    };
  });

  for (const obj of quiz.questions) {
    const questionAnswers = obj.answers;
    const answers = quiz.answers.filter((obj) =>
      questionAnswers.includes(obj.answer_id)
    );
    let height = 20;

    for (const answer of answers) {
      height += 45;
      const newNode = {
        id: answer.answer_id,
        position: { x: 10, y: height },
        data: { label: answer.title, nodeType: "Answer" },
        sourcePosition: "right",
        targetPosition: "left",
        parentNode: obj.question_id,
        extent: "parent",
      };

      initialNodes.push(newNode);
      initialEdges.push({
        id: generateUniqueId(),
        source: answer.answer_id,
        target: getQuestion(answer.next_id),
      });
    }
  }

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  );

  return (
    <>
      <NodeFlyout
        node={selected}
        isFlyoutVisible={isFlyoutVisible}
        toggleIsFlyoutVisible={toggleIsFlyoutVisible}
      />

      <ReactFlowProvider>
        <div style={{ width: "94vw", height: "90vh" }}>
          <Flow
            name={data.quiz.name}
            initialNodes={layoutedNodes}
            initialEdges={layoutedEdges}
            setSelected={setSelected}
          />
        </div>
      </ReactFlowProvider>
    </>
  );
}
