import React from "react";

import { EuiSelect } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const AISLES = gql`
  query aisles($first: Int) {
    aisles(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function AislesSelect({value, onChange, name }) {
  const { loading, error, data } = useQuery(AISLES, {
    variables: { first: 200 },
  });

  if (error) return `Error! ${error}`;

  let options = [{ value: "", text: "--" }];

  if (data) {
    data.aisles.edges.forEach((a) =>
      options.push({ value: a.node.id, text: a.node.name })
    );
    let optFilter = options.filter((o) => o.value === value);
    if (value !== undefined && optFilter && !optFilter.length) {
      value = undefined;
    }
    return (
      <EuiSelect
        hasNoInitialSelection
        id="selectDocExample"
        options={options}
        value={value}
        onChange={onChange}
        isLoading={loading}
        name={name}
      />
    );
  }
  return null;
}
