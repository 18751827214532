import { gql } from "@apollo/client";

const INGREDIENTS = gql`
  query ingredients(
    $query: String
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    ingredients(
      query: $query
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          name
          slug
          alias
          isActive
          aisle {
            name
          }
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export default INGREDIENTS;
