import React, { Fragment, useState } from "react";

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAccordion,
  EuiFormRow,
  EuiSwitch,
  EuiSpacer,
  EuiDualRange,
  EuiButton,
  EuiSelectable,
  EuiFieldNumber,
} from "@elastic/eui";

import RecipesList from "../components/RecipeList";
import RecipeQueryStats from "../components/RecipeQueryStats";
import IngredientsComboBox from "../components/IngredientsComboBox";
import TagsComboBox from "../components/TagsComboBox";
import CoursesComboBox from "../components/CoursesComboBox";
import AuthorSelect from "../components/AuthorSelect";
import SourceSelect from "../components/SourceSelect";
import RestrictionComboBox from "../components/RestrictionComboBox";
import { Link } from "@reach/router";

function Recipes() {
  const Meals = [
    { label: "Breakfast", key: "Breakfast", checked: "on" },
    { label: "Lunch", key: "Lunch", checked: "on" },
    { label: "Dinner", key: "Dinner", checked: "on" },
    { label: "Snack", key: "Snack", checked: "on" },
  ];

  const [query, setQuery] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isPremium, setIsPremium] = useState(false);
  const [isPurchasable, setIsPurchasable] = useState(false);
  const [sourceUrlWorks, setSourceUrlWorks] = useState(false);
  const [hasImage, setHasImage] = useState(true);
  const [useMatchPhrase, setUseMatchPhrase] = useState(false);
  const [caloriesRange, setCaloriesRangeChange] = useState([0, 5000]);
  const [proteinRange, setProteinRangeChange] = useState([0, 5000]);
  const [fatRange, setFatRangeChange] = useState([0, 5000]);
  const [carbsRange, setCarbsRangeChange] = useState([0, 5000]);
  const [omega3Range, setOmega3RangeChange] = useState([0, 5000]);
  const [ingredientRange, setIngredientRangeChange] = useState([0, 200]);
  const [totalTimeRange, setTotalTimeRangeChange] = useState([0, 2700]);
  const [meals, setMeals] = useState(Meals);
  const [hasKnownIngredients, setHasKnownIngredients] = useState(true);
  const [mustIngredients, setMustIngredients] = useState([]);
  const [courses, setCourses] = useState([]);
  const [tags, setTags] = useState([]);
  const [author, setAuthor] = useState(null);
  const [source, setSource] = useState(null);
  const [size, setSize] = useState(30);
  const [restrictions, setRestrictions] = useState([]);

  const [filters, setFilters] = useState({
    isActive: true,
    isPremium: false,
    isPurchasable: false,
    hasImage: true,
    useMatchPhrase: false,
    caloriesRange: [0, 4000],
    proteinRange: [0, 4000],
    fatRange: [0, 4000],
    carbsRange: [0, 4000],
    omega3Range: [0, 4000],
    ingredientRange: [0, 100],
    totalTimeRange: [0, 2700],
    meals: Meals,
    hasKnownIngredients: true,
    mustIngredients: [],
    tags: [],
    courses: [],
    author: null,
    source: null,
    size: 30,
    restrictions: [],
    sourceUrlWorks: false,
  });

  function toggleIsActive() {
    setIsActive(!isActive);
  }

  function toggleIsPremium() {
    setIsPremium(!isPremium);
  }

  function toggleIsPurchasable() {
    setIsPurchasable(!isPurchasable);
  }

  function toggleSourceUrlWorks() {
    setSourceUrlWorks(!sourceUrlWorks);
  }

  function toggleHasImage() {
    setHasImage(!hasImage);
  }

  function toggleUseMatchPhrase() {
    setUseMatchPhrase(!useMatchPhrase);
  }

  function caloriesRangeChange(range) {
    setCaloriesRangeChange(range);
  }

  function proteinRangeChange(range) {
    setProteinRangeChange(range);
  }

  function fatRangeChange(range) {
    setFatRangeChange(range);
  }

  function carbsRangeChange(range) {
    setCarbsRangeChange(range);
  }

  function omega3RangeChange(range) {
    setOmega3RangeChange(range);
  }

  function ingredientRangeChange(range) {
    setIngredientRangeChange(range);
  }

  function totalTimeRangeChange(range) {
    setTotalTimeRangeChange(range);
  }

  function toggleHasKnownIngredients() {
    setHasKnownIngredients(!hasKnownIngredients);
  }

  function mustIngredientsChanged(ings) {
    setMustIngredients(ings);
  }

  function coursesChanged(courses) {
    setCourses(courses);
  }

  function tagsChanged(tags) {
    setTags(tags);
  }

  function search() {
    setFilters({
      query: query,
      isActive: isActive,
      isPremium: isPremium,
      isPurchasable: isPurchasable,
      sourceUrlWorks: sourceUrlWorks,
      hasImage: hasImage,
      useMatchPhrase: useMatchPhrase,
      caloriesRange: caloriesRange,
      proteinRange: proteinRange,
      fatRange: fatRange,
      carbsRange: carbsRange,
      omega3Range: omega3Range,
      ingredientRange: ingredientRange,
      totalTimeRange: totalTimeRange,
      meals: meals,
      hasKnownIngredients: hasKnownIngredients,
      mustIngredients: mustIngredients,
      tags: tags,
      courses: courses,
      author: author,
      source: source,
      size: size,
      restrictions: restrictions,
    });
  }

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFieldSearch
            placeholder="Search..."
            fullWidth
            value={query}
            incremental={false}
            onSearch={search}
            onChange={(e) => setQuery(e.target.value)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={search}>Search</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="l" />


      <Link to={`/recipes/create`}>
          <EuiButton fill>+ Recipe</EuiButton>
        </Link>

      <EuiAccordion id="searchFilters001" buttonContent="Search Settings">
        <EuiSpacer size="s" />

        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label="">
              <EuiSwitch
                label="Use Match Phrase Search"
                onChange={toggleUseMatchPhrase}
                checked={useMatchPhrase}
              />
            </EuiFormRow>
            <EuiFormRow label="" helpText="">
              <EuiSwitch
                label="Only Active"
                onChange={toggleIsActive}
                checked={isActive}
              />
            </EuiFormRow>
            <EuiFormRow label="">
              <EuiSwitch
                label="Only Premium"
                onChange={toggleIsPremium}
                checked={isPremium}
              />
            </EuiFormRow>
            <EuiFormRow label="">
              <EuiSwitch
                label="Only Is Purchasable"
                onChange={toggleIsPurchasable}
                checked={isPurchasable}
              />
            </EuiFormRow>
            <EuiFormRow label="">
              <EuiSwitch
                label="Only with Image"
                onChange={toggleHasImage}
                checked={hasImage}
              />
            </EuiFormRow>
            <EuiFormRow label="">
              <EuiSwitch
                label="Only with working URL"
                onChange={toggleSourceUrlWorks}
                checked={sourceUrlWorks}
              />
            </EuiFormRow>
            <EuiFormRow label="">
              <EuiSwitch
                label="Only Known Ingredients"
                onChange={toggleHasKnownIngredients}
                checked={hasKnownIngredients}
              />
            </EuiFormRow>
            <EuiFormRow label="Author">
              <AuthorSelect item={author} setItem={setAuthor} />
            </EuiFormRow>
            <EuiFormRow label="Source">
              <SourceSelect item={source} setItem={setSource} />
            </EuiFormRow>
            <EuiFormRow label="Meals" helpText="">
              <EuiSelectable
                options={meals}
                onChange={(newMeals) => setMeals(newMeals)}
                listProps={{ bordered: true }}
              >
                {(list) => list}
              </EuiSelectable>
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow label="# Results">
              <EuiFieldNumber
                placeholder="Page size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
                min={0}
                max={500}
                aria-label="Page size"
              />
            </EuiFormRow>
            <EuiFormRow>
              <IngredientsComboBox
                ingredientsChanged={mustIngredientsChanged}
              />
            </EuiFormRow>
            <EuiFormRow label="" helpText="">
              <TagsComboBox currentTags={tags} setTags={tagsChanged} />
            </EuiFormRow>
            <EuiFormRow label="" helpText="">
              <CoursesComboBox itemsChanged={coursesChanged} />
            </EuiFormRow>
            <EuiFormRow label="Ingredients Range" helpText="">
              <EuiDualRange
                id="303,skskn3sd30ks03lllssds9332"
                value={ingredientRange}
                onChange={ingredientRangeChange}
                showInput
                max={200}
              />
            </EuiFormRow>
            <EuiFormRow label="Total time Range" helpText="">
              <EuiDualRange
                id="4949403mdlk030303ldl"
                value={totalTimeRange}
                onChange={totalTimeRangeChange}
                showInput
                max={2700}
              />
            </EuiFormRow>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Restrictions">
                  <RestrictionComboBox
                    items={restrictions}
                    setItem={setRestrictions}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFormRow label="Calories per serving range" helpText="">
              <EuiDualRange
                id="sksksk093k"
                value={caloriesRange}
                onChange={caloriesRangeChange}
                showInput
                max={5000}
              />
            </EuiFormRow>
            <EuiFormRow label="Proteins range" helpText="">
              <EuiDualRange
                id="sksksksksksk03kdks03k"
                value={proteinRange}
                onChange={proteinRangeChange}
                showInput
                max={5000}
              />
            </EuiFormRow>
            <EuiFormRow label="Fat range" helpText="">
              <EuiDualRange
                id="03k30dk30-dkl3-3m"
                value={fatRange}
                onChange={fatRangeChange}
                showInput
                max={5000}
              />
            </EuiFormRow>
            <EuiFormRow label="Carbs range" helpText="">
              <EuiDualRange
                id="03k30dksd30ks03llls32"
                value={carbsRange}
                onChange={carbsRangeChange}
                showInput
                max={5000}
              />
            </EuiFormRow>
            <EuiFormRow label="Omega3" helpText="">
              <EuiDualRange
                id="03k30dksd30kddsasdflls32"
                value={omega3Range}
                onChange={omega3RangeChange}
                showInput
                max={5000}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="m" />
      </EuiAccordion>

      <EuiSpacer size="m" />

      <RecipeQueryStats
        query={filters.query}
        isActive={filters.isActive}
        isPremium={filters.isPremium}
        isPurchasable={filters.isPurchasable}
        hasImage={filters.hasImage}
        useMatchPhrase={filters.useMatchPhrase}
        caloriesRange={filters.caloriesRange}
        proteinRange={filters.proteinRange}
        fatRange={filters.fatRange}
        carbsRange={filters.carbsRange}
        omega3Range={filters.omega3Range}
        ingredientRange={filters.ingredientRange}
        totalTimeRange={filters.totalTimeRange}
        meals={filters.meals}
        hasKnownIngredients={filters.hasKnownIngredients}
        mustIngredients={filters.mustIngredients}
        tags={filters.tags}
        courses={filters.courses}
        author={filters.author}
        source={filters.source}
        restrictions={filters.restrictions}
        sourceUrlWorks={filters.sourceUrlWorks}
      />

      <RecipesList
        query={filters.query}
        isActive={filters.isActive}
        isPremium={filters.isPremium}
        isPurchasable={filters.isPurchasable}
        hasImage={filters.hasImage}
        useMatchPhrase={filters.useMatchPhrase}
        caloriesRange={filters.caloriesRange}
        proteinRange={filters.proteinRange}
        fatRange={filters.fatRange}
        carbsRange={filters.carbsRange}
        omega3Range={filters.omega3Range}
        ingredientRange={filters.ingredientRange}
        totalTimeRange={filters.totalTimeRange}
        size={filters.size}
        meals={filters.meals}
        hasKnownIngredients={filters.hasKnownIngredients}
        mustIngredients={filters.mustIngredients}
        tags={filters.tags}
        courses={filters.courses}
        author={filters.author}
        source={filters.source}
        restrictions={filters.restrictions}
        sourceUrlWorks={filters.sourceUrlWorks}
      />
    </Fragment>
  );
}

export default Recipes;
