import { gql } from "@apollo/client";

const UPDATE_FOOD = gql`
mutation updateFood(
  $id: ID!
  $portions: [PortionInput]
  $commonNames: [String]
) {
  updateFood(
    id: $id
    portions: $portions
    commonNames: $commonNames
  ) {
    id
  }
}
`;

export default UPDATE_FOOD