import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "@reach/router";

import FOOD_DETAIL from "../querys/foodDetail";

import {
  EuiProgress,
  EuiTitle,
  EuiSpacer,
  EuiDescriptionList,
  EuiFlexItem,
  EuiFlexGroup,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiButton,
} from "@elastic/eui";

function Food({ foodId }) {
  const id = btoa(`Food:${foodId}`);
  const { loading, error, data } = useQuery(FOOD_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return (
    <Fragment>
      <EuiTitle size="l">
        <h1>{data.food.description}</h1>
      </EuiTitle>
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiDescriptionList>
            <EuiDescriptionListTitle>Name</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              {data.food.description}
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      {id}

      <EuiSpacer />
      <Link to={`/fooddata/${foodId}/change`}>
        <EuiButton fill>Edit</EuiButton>
      </Link>
    </Fragment>
  );
}

export default Food;
