import React from "react";
import { useQuery } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";
import { gql } from "@apollo/client";

import ChangeIngredientLine from "../components/ChangeIngredientLine";

const INGREDIEN_LINE_STATS = gql`
  query ingredientLinesStats($id: ID!) {
    ingredientLinesStats(id: $id) {
      count
      authors {
        docCount
        key
      }
      ingredientLine {
        ingredient
        isKnownIngredient
        ingredientLine {
          original
        }
        walmart {
          department
          aisle
          shelf
        }
        quantity {
          numeric
        }
        unit {
          original
        }
        usda {
          grams
          measureSource
          usdaId
          usdaName
        }
      }
    }
  }
`;

export default function ChangeFood({ id}) {
  const { loading, error, data } = useQuery(INGREDIEN_LINE_STATS, {
    variables: {id: id},
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return (
    <ChangeIngredientLine
      ingredientLine={data.ingredientLinesStats.ingredientLine}
      data={data}
    />
  );
}
