import React from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";
import { ReactFlowProvider, MarkerType } from "reactflow";
import "reactflow/dist/style.css";
import Flow from "../components/Quiz/Flow";
import { generateUniqueId } from "../utils";
import {
  getLayoutedElements,
  nodeQuestionWidth,
  nodeQuestionHeight,
} from "../components/Quiz/Helpers";

const ASSESSMENT_FLOW = gql`
  query assessmentFlow($id: ID!) {
    assessmentFlow(id: $id)
  }
`;

export default function AssessmentFlow({ id }) {
  const { loading, error, data } = useQuery(ASSESSMENT_FLOW, {
    variables: { id: id },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const assessment = data.assessmentFlow;

  const initialEdges = assessment.edges.map((item) => {
    return {
      id: generateUniqueId(),
      source: item.source,
      target: item.target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
      data: {
        sourceType: item.sourceType,
      },
    };
  });

  const initialNodes = assessment.nodes.map((item) => {
    if (item.type === "Answer") {
      return {
        id: item.id,
        position: { x: 10, y: 50 },
        data: { label: item.label, nodeType: "Answer" },
        sourcePosition: "right",
        targetPosition: "left",
        parentNode: item.parent_node,
        extent: "parent",
      };
    }
    return {
      id: item.id,
      type: item.is_initial_question ? "input" : "default",
      position: { x: 0, y: 0 },
      data: { label: item.label, nodeType: item.type },
      style: {
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        width: nodeQuestionWidth,
        height: nodeQuestionHeight,
      },
    };
  });

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  );

  return (
    <>
      <ReactFlowProvider>
        <div style={{ width: "94vw", height: "90vh" }}>
          <Flow
            name={assessment.title}
            assessmentId={id}
            initialNodes={layoutedNodes}
            initialEdges={layoutedEdges}
          />
        </div>
      </ReactFlowProvider>
    </>
  );
}
