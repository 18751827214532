import { gql } from "@apollo/client";

const CONFIRM_FOOD_LOG_ASSISTANT = gql`
  mutation confirmFoodLogAssistant($input: ConfirmFoodLogAssistantInput!) {
    confirmFoodLogAssistant(input: $input) {
      success
      message
      assistantMessage {
        id
        isLogged
      }
    }
  }
`;
export default CONFIRM_FOOD_LOG_ASSISTANT