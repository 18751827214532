import { gql } from "@apollo/client";

const MEAL_PLAN = gql`
  query mealPlanner(
    $calories: Int
    $protein: Float
    $carbs: Float
    $fat: Float
    $omega3: Float
    $days: Int
    $restrictions: [String]
    $program: String
  ) {
    mealPlan(
      calories: $calories
      protein: $protein
      carbs: $carbs
      fat: $fat
      omega3: $omega3
      days: $days
      restrictions: $restrictions
      program: $program
    ) {
      id
      name
      protein
      calories
      fat
      omega3
      carbs
      mealTag
      servingWeight
      maxNumOfServings
      numberOfServings
      ingredients
      mainImage
    }
  }
`;

export default MEAL_PLAN;
