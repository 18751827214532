import { gql } from "@apollo/client";

const WELLNESS_GPT_LOG = gql`
query wellnessGptLog($first: Int $cursor: String){
  wellnessGptLog(first: $first after: $cursor) {
    edges {
      cursor
      node {
        id
        isAssistantTester
        isInitialMessage
        isUsingConstraints
        isUsingKnowlageBase
        isUsingEscalations
        toneOfVoice
        language
        assistant {
          id
          name
        }
        interaction {
          id
          interaction
        }
        user
        userMessage
        profile {
          id
        }
        gptMessages
        gptResponse
        indexedAt
        date
        time
        model
        temperature
      }
    }
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export default WELLNESS_GPT_LOG;
