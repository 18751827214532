import React from "react";
import { useQuery } from "@apollo/client";

import { EuiBasicTable, EuiProgress } from "@elastic/eui";

import HISTORY from "../querys/history";

function FoodHistory({ foodId }) {
  const id = btoa(`Food:${foodId}`);
  const { loading, error, data } = useQuery(HISTORY, {
    variables: { objectType: "FOOD_DATA", id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const columns = [
    {
      field: "date",
      name: "Date",
      render: (date) => {
        return `${date}`;
      },
    },
    {
      field: "user",
      name: "User",
      render: (user) => {
        return `${user}`;
      },
    },
    {
      field: "action",
      name: "Action",
      render: (action) => {
        return `${action}`;
      },
    },
    {
      field: "change",
      name: "Change",
      render: (change) => {
        return `${change}`;
      },
    }
  ];

  const getRowProps = (item) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${id}`),
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  return (
      <EuiBasicTable
        items={data.allHistory}
        rowHeader="history"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
      />
  );
}

export default FoodHistory;
