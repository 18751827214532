import React, { useState, Fragment } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiBreadcrumbs,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiToast,
  EuiI18nNumber,
  EuiFlexItem,
  EuiFlexGroup,
  EuiStat,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate, Link } from "@reach/router";

import UPDATE_FOOD from "../mutations/updateFood";

export default function ChangeIngredientLineForm({ ingredientLine, data }) {
  const [displayToast, setDisplayToast] = useState(false);
  const [name, setName] = useState(ingredientLine.ingredientLine.original);
  const [usdaId, setUsdaId] = useState(ingredientLine.usda.usdaId);

  const usdaFoodName = ingredientLine.usda? ingredientLine.usda.usdaName : ''

  const [updateFood, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_FOOD, {
      errorPolicy: "all",
      onCompleted(data) {
        setDisplayToast(true);
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );
  const _update = () => {
    let variables = {
      id: ingredientLine.id,
    };
    updateFood({ variables: variables });
  };

  const breadcrumbs = [
    {
      text: "Ingredient Lines",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/ingredient-lines`);
      },
    },
    {
      text: "Edit Ingredient Line",
    },
  ];

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Food was updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  return (
    <Fragment>
      <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
      <EuiSpacer />
      <EuiPageContentHeaderSection>
        <EuiTitle size="s">
          <h1>Change Ingredient Line</h1>
        </EuiTitle>
        <Link to={`/fooddata/${ingredientLine.id}/history`}>History</Link>
      </EuiPageContentHeaderSection>
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiStat
            title={
              data ? (
                <EuiI18nNumber value={data.ingredientLinesStats.count} />
              ) : (
                0
              )
            }
            description="Total Recipes"
            titleSize="s"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiStat
            title={
              data ? (
                <EuiI18nNumber
                  value={data.ingredientLinesStats.authors.length}
                />
              ) : (
                0
              )
            }
            description="Total authors"
            titleSize="s"
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiForm component="form">
        <EuiFormRow label="Ingredient Line">
          <EuiFieldText
            name="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="USDA ID" helpText={`Current food: ${usdaFoodName}`}>
          <EuiFieldText
            name="Name"
            value={usdaId}
            onChange={(e) => setUsdaId(e.target.value)}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton
          fill
          onClick={_update}
          isLoading={mutationLoading}
        >
          Save
        </EuiButton>
      </EuiForm>

      <EuiSpacer />
      <Toast />
    </Fragment>
  );
}
