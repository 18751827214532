import React from "react";
import { EuiComment, EuiMarkdownFormat, EuiAvatar } from "@elastic/eui";
import moment from "moment";

export default function NotesList({ notes }) {
  const notesList = notes.map((item, i) => {
    var utcDate = moment.utc(item.node.createdAt).toDate();
    var timestamp = moment(utcDate).local().fromNow();

    return (
      <EuiComment
        username={item.node.user.email}
        type="regular"
        event="Added a note"
        timestamp={timestamp}
        key={`comment-activity-${i}`}
        timelineIcon={<EuiAvatar size="l" name={item.node.user.email} />}
      >
        <EuiMarkdownFormat>{item.node.note}</EuiMarkdownFormat>
      </EuiComment>
    );
  });

  return <div>{notesList}</div>;
}
