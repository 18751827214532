import React from "react";
import { navigate } from "@reach/router";
import { EuiProgress, EuiBasicTable } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import FOODS from "../querys/foods";
import PromoteFood from "./PromoteFood";

function Meals({ query }) {
  const { loading, error, data } = useQuery(FOODS, {
    variables: { query: query, first: 50 },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const actions = [
    {
      name: "Edit",
      description: "Edit",
      icon: "documentEdit",
      type: "icon",
      onClick: (item) => {
        navigate(`/fooddata/${item.node.foodDataId}`);
      },
    },
  ];

  const columns = [
    {
      name: "Description",
      field: "node.description",
    },
    {
      name: "Data Type",
      field: "node.dataType",
    },
    {
      name: "Brand Owner",
      field: "node.brandOwner",
    },
    {
      name: "Serving",
      field: "node.householdServingFulltext",
    },
    {
      name: "Branded Food Category",
      field: "node.brandedFoodCategory",
    },
    {
      field: "node",
      name: "Promote",
      render: (node) => {
        return <PromoteFood id={node.id} value={node.promote} />;
      },
    },
    {
      name: "Actions",
      actions,
    },
  ];

  return (
    <EuiBasicTable
      compressed={true}
      items={data.foods.edges}
      rowHeader="firstName"
      columns={columns}
    />
  );
}

export default Meals;
