import React, { useState, Fragment } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiBreadcrumbs,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiToast,
  EuiText,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate, Link } from "@reach/router";

import PortionFieldTextList from "../components/PortionFieldTextList";
import ListFieldText from "../components/ListFieldText";
import UPDATE_FOOD from "../mutations/updateFood";

function ChangeFoodForm({ food }) {
  const [displayToast, setDisplayToast] = useState(false);
  const [name, setName] = useState(food.description);
  const [portions, setPortions] = useState(food.portions ? food.portions : []);
  const [commonNames, setCommonNames] = useState(
    food.commonNames ? food.commonNames : []
  );

  const [
    updateFood,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_FOOD, {
    errorPolicy: "all",
    onCompleted(data) {
      setDisplayToast(true);
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );
  const _updateRecipe = () => {
    let variables = {
      id: food.id,
      portions: portions.map(
        ({ modifier, seqNum, amount, gramWeight, unit }) => ({
          modifier,
          seqNum,
          amount,
          gramWeight,
          unit,
        })
      ),
    };
    if (food.commonNames !== commonNames) variables.commonNames = commonNames;
    updateFood({ variables: variables });
  };

  const breadcrumbs = [
    {
      text: "Fooddata",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/fooddata`);
      },
    },
    {
      text: `${food.foodDataId}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/fooddata/${food.foodDataId}`);
      },
    },
    {
      text: "Change",
    },
  ];

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Food was updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  return (
    <Fragment>
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="An example of EuiBreadcrumbs"
      />
      <EuiSpacer />
      <EuiPageContentHeaderSection>
        <EuiTitle size="s">
          <h1>Change Food Data</h1>
        </EuiTitle>
        <Link to={`/fooddata/${food.foodDataId}/history`}>History</Link>
      </EuiPageContentHeaderSection>
      <EuiSpacer />

      <EuiForm component="form">
        <EuiFormRow label="Name">
          <EuiFieldText
            name="Name"
            value={name}
            readOnly
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Commun Names">
          <ListFieldText
            list={commonNames}
            setList={setCommonNames}
            addText="Add another Commun Name"
          />
        </EuiFormRow>

        <EuiFormRow
          label="Portions"
          fullWidth
          labelAppend={
            <EuiText size="xs">
              Modifier | Sequence Number | Amount | Grams Weight | Unit
            </EuiText>
          }
          helpText="!Note: When adding new portions 'Modifier' is the relevant value not 'Unit'"
        >
          <PortionFieldTextList
            list={portions}
            setList={setPortions}
            addText="Add another portion"
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton
          fill
          onClick={_updateRecipe}
          isLoading={mutationLoading}
        >
          Save
        </EuiButton>
      </EuiForm>

      <EuiSpacer />
      <Toast />
    </Fragment>
  );
}

export default ChangeFoodForm;
