import { gql } from "@apollo/client";

const RECIPE_ANNOTATE_NUTRIENTS = gql`
  mutation RecipeAnnotateNutrients($input: RecipeAnnotateNutrientsInput!) {
    recipeAnnotateNutrients(input: $input) {
      success
      recipe {
        id
        databaseId
        servingWeight
        nutrientsPerServing {
          calories
          choline
          sugar
          fiber
          saturatedFat
          monounsaturatedFat
          polyunsaturatedFat
          transFat
          cholesterol
          sodium
          potassium
          vitaminA
          vitaminC
          vitaminB6
          vitaminB7
          vitaminB12
          vitaminD
          vitaminE
          calcium
          iron
          netcarbs
          protein
          carbs
          fat
          dha
          dpa
          epa
          ala
          omega3
        }
        caloriesPerServing {
          protein
          carbs
          fat
          dha
          dpa
          epa
          ala
        }
      }
    }
  }
`;

export default RECIPE_ANNOTATE_NUTRIENTS;
