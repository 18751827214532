import React from "react";

import {
  EuiBasicTable,
  EuiTitle,
  EuiSpacer,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";


export default function ProductsGlobalSearchResults({ items }) {
  const columns = [
    {
      field: "node",
      name: "",
      render: (node) => {
        return (
          <EuiAvatar
            type="space"
            size="l"
            name={node.name}
            imageUrl={node.image}
          />
        );
      },
      width: "70px",
    },
    {
      field: "node",
      name: "",
      align: "left",
      width: "80%",
      render: (node) => {
        return (
          <div>
            <EuiFlexGroup>
              <EuiFlexItem style={{ margin: 7 }}>
                <b>{node.name}</b>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size={"s"} />
            <EuiFlexGroup>
              <EuiFlexItem style={{ margin: 7 }}>
                <EuiText size="xs">
                  {node.brand} - {node.defaultMeasure}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
        );
      },
    },
    {
      field: "node",
      name: "",
      render: (node) => `${Math.round(node.nutrientsPerServing.calories)} cal`,
      align: "right",
    },
  ];

  return (
    <>
      <EuiSpacer size="l" />
      <EuiTitle size="xs">
        <h1>Products</h1>
      </EuiTitle>
      <EuiBasicTable
        items={items}
        itemId="id"
        columns={columns}
        rowHeader="name"
      />
    </>
  );
}
