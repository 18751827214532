import React, { useState } from "react";

import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiButtonIcon,
  EuiText,
  EuiTitle,
} from "@elastic/eui";

function RecipeFlyout({ item }) {
  const [isFlyoutVisible, toggleIsFlyoutVisible] = useState(false);

  const open = () => {
    toggleIsFlyoutVisible(true);
  };

  const close = () => {
    toggleIsFlyoutVisible(false);
  };

  let flyout;
  let courses;

  if (item.courses) {
    courses = item.courses.map((course) => <li>{course}</li>);
  } else {
    courses = [];
  }

  const mealTags = item.mealTags
    ? item.mealTags.map((mealTag) => <li>{mealTag}</li>)
    : [];

  const tags = item.tags ? item.tags.map((tag) => <li>{tag}</li>) : [];

  const ingredientLines = item.ingredientLines.map((ingredientLine) => (
    <li>{ingredientLine}</li>
  ));

  const ingredients = item.ingredients
    ? item.ingredients.map((ingredient) => <li>{ingredient}</li>)
    : [];

  const nutritionalInfo = (item) => {
    return (
      <ul>
        <li>Calories: {item.calories}</li>
        <li>Protein: {item.protein}</li>
        <li>Carbs: {item.carbs}</li>
        <li>fat: {item.fat}</li>
        <li>sugar: {item.sugar}</li>
        <li>fiber: {item.fiber}</li>
        <li>saturated_fat: {item.saturated_fat}</li>
        <li>monounsaturated_fat: {item.monounsaturated_fat}</li>
        <li>polyunsaturated_fat: {item.polyunsaturated_fat}</li>
        <li>trans_fat: {item.trans_fat}</li>
        <li>cholesterol: {item.cholesterol}</li>
        <li>sodium: {item.sodium}</li>
        <li>potassium: {item.potassium}</li>
        <li>vitamin_a: {item.vitamin_a}</li>
        <li>vitamin_c: {item.vitamin_c}</li>
        <li>calcium: {item.calcium}</li>
        <li>iron: {item.iron}</li>
        <li>netcarbs: {item.netcarbs}</li>
      </ul>
    );
  };

  const rawNutrients = (item) => {
    return (
      <ul>
        <li>Calories: {item.calories}</li>
        <li>Protein: {item.protein}</li>
        <li>Carbs: {item.carbs}</li>
        <li>fat: {item.fat}</li>
        <li>sugar: {item.sugar}</li>
        <li>fiber: {item.fiber}</li>
        <li>saturated_fat: {item.saturated_fat}</li>
        <li>monounsaturated_fat: {item.monounsaturated_fat}</li>
        <li>polyunsaturated_fat: {item.polyunsaturated_fat}</li>
        <li>trans_fat: {item.trans_fat}</li>
        <li>cholesterol: {item.cholesterol}</li>
        <li>sodium: {item.sodium}</li>
        <li>potassium: {item.potassium}</li>
        <li>vitamin_a: {item.vitamin_a}</li>
        <li>vitamin_c: {item.vitamin_c}</li>
        <li>calcium: {item.calcium}</li>
        <li>iron: {item.iron}</li>
        <li>netcarbs: {item.netcarbs}</li>
      </ul>
    );
  };

  const macros = (item) => {
    return item.nutritionalInfo ? (
      <ul>
        <li>
          <b>Protein:</b>{" "}
          {Math.round(item.nutritionalInfo.protein * item.servingWeight)}
        </li>
        <li>
          <b>Carbs:</b>{" "}
          {Math.round(item.nutritionalInfo.carbs * item.servingWeight)}
        </li>
        <li>
          <b>Fat:</b>{" "}
          {Math.round(item.nutritionalInfo.fat * item.servingWeight)}
        </li>
      </ul>
    ) : (
      `-`
    );
  };

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout onClose={close} aria-labelledby="flyoutTitle">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">{item.name}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiText size="xs">
            <h3>Has Trainable Ingredients?</h3>
            <p>{item.hasTrainableIngredients ? "YES" : "NO"}</p>

            <h3>Courses</h3>
            <ul>{courses}</ul>

            <h3>Meal tags</h3>
            <ul>{mealTags}</ul>

            <h3>Tags</h3>
            <ul>{tags}</ul>

            <h3>Ingredients</h3>
            <ul>{ingredients}</ul>

            <h3>Ingredient Lines</h3>
            <ul>{ingredientLines}</ul>

            <h3>Serving Weight</h3>
            {item.servingWeight}

            <h3>Weight In Grams</h3>
            {item.weightInGrams}

            <h3>Calories per serving</h3>
            {Math.round(item.nutrientsPerServing.calories)}

            <h3>Macros</h3>
            {macros(item)}

            <h3>Nutrients</h3>
            {nutritionalInfo(item.nutritionalInfo)}

            <h3>Raw Database Nutrients</h3>
            {rawNutrients(item.rawNutrients)}
          </EuiText>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  return (
    <div>
      <EuiButtonIcon
        color="primary"
        onClick={open}
        iconType="eye"
        aria-label="Next"
      />
      {flyout}
    </div>
  );
}

export default RecipeFlyout;
