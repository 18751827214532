import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const ALL_MEAL_TAGS = gql`
  {
    allMealTags
  }
`;

function MealTagsComboBox({ currentTags, setTags }) {
  const { loading, error, data } = useQuery(ALL_MEAL_TAGS);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.allMealTags.forEach((tag) => options.push({ label: tag }));
  }

  return (
    <EuiComboBox
      placeholder="Tags"
      options={options}
      selectedOptions={currentTags}
      onChange={(tags) => setTags(tags)}
      isClearable={true}
      data-test-subj="recipeMealTags"
      isLoading={loading}
      fullWidth={true}
    />
  );
}
export default MealTagsComboBox;
