import React from "react";
import ReactDOM from "react-dom";
//import '@elastic/eui/dist/eui_theme_dark.css';
// import "@elastic/eui/dist/eui_theme_amsterdam_light.css";

import './styles/globals.css'
// import "@elastic/eui/dist/eui_theme_amsterdam_light.css";
import './styles/suggestic-light/eui_theme_suggestic_light.css'
import './styles/euiCustom.css'

import Login from "./pages/login";
import Pages from "./pages";
import conf from "./utils";

import * as serviceWorker from "./serviceWorker";
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';

import {
  ApolloProvider,
  gql,
  ApolloLink,
  ApolloClient,
  useQuery,
  concat,
} from "@apollo/client";

import { cache } from "./cache";

const httpLink = new createUploadLink({
  uri: `${conf.API_URL}/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: token ? `Token ${token}` : "",
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink),
});

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

function IsLoggedIn() {
  const { data } = useQuery(IS_LOGGED_IN);
  return data.isLoggedIn ? <Pages /> : <Login />;
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <IsLoggedIn />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
