import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { navigate } from "@reach/router";

import {
  EuiProgress,
  EuiTitle,
  EuiSpacer,
  EuiCallOut,
  EuiButton,
  EuiFormRow,
  EuiForm,
  EuiBreadcrumbs,
  EuiToast,
} from "@elastic/eui";

import RECIPE_DETAIL from "../querys/recipeDetail";
import UPDATE_RECIPE_INGREDIENTS from "../mutations/updateRecipeIngredients";
import ListFieldText from "../components/ListFieldText";

function RecipeIngredientForm({ data }) {
  const [displayToast, setDisplayToast] = useState(false);
  const [ingredients, setIngredients] = useState(
    data.recipe.ingredients ? data.recipe.ingredients : []
  );

  const [
    updateRecipeIngredients,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_RECIPE_INGREDIENTS, {
    errorPolicy: "all",
    onCompleted(data) {
      setDisplayToast(true);
      console.log(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Recipe was updated"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  const _update = () => {
    let variables = {
      id: data.recipe.id,
      ingredients: ingredients
    }
    updateRecipeIngredients({ variables: variables });
  };

  if (mutationError)
    return (
      <pre>
        Bad:{" "}
        {mutationError.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  return (
    <>
      <EuiTitle size="l">
        <h1>{data.recipe.name}</h1>
      </EuiTitle>
      <EuiSpacer />

      <EuiCallOut title="Proceed with caution!" color="warning" iconType="help">
        <p>
          Updating this will override annotations from the ILP(Ingredient Line
          Parser).
        </p>
      </EuiCallOut>

      <EuiSpacer />

      <EuiForm component="form">
        <EuiFormRow label="Ingredients" helpText="">
          <ListFieldText
            list={ingredients}
            setList={setIngredients}
            addText="Add another ingredient"
            parse={false}
          />
        </EuiFormRow>
      </EuiForm>

      <EuiButton fill onClick={_update} isLoading={mutationLoading}>
        Save
      </EuiButton>
      <EuiSpacer />
      <Toast />
    </>
  );
}

export default function EditRecipeIngredients({ recipeId }) {
  const id = btoa(`Recipe:${recipeId}`);
  const breadcrumbs = [
    {
      text: "Recipes",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes`);
      },
    },
    {
      text: `${recipeId}`,
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes/${recipeId}`);
      },
    },
    {
      text: "Edit recipe ingredients",
    },
  ];

  const { loading, error, data } = useQuery(RECIPE_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return (
    <>
      <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} />
      <EuiSpacer />
      <RecipeIngredientForm data={data} />
    </>
  );
}
