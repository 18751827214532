import React, { useState } from 'react';

import { EuiComboBox } from '@elastic/eui';
import { useQuery } from '@apollo/client';
import { gql } from "@apollo/client";

const ALL_COURSES = gql`
{
    allCourses
}
`;

export default function CoursesComboBox({itemsChanged}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { loading, error, data } = useQuery(ALL_COURSES);

  if (error) return `Error! ${error}`;

  const options = []
  if (data){
    data.allCourses.forEach(i => options.push({ label: i }));
  }

  function onChange(items) {
    setSelectedOptions(items)
    itemsChanged(items)
  }

  return (

    <EuiComboBox
      placeholder="With courses"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      isClearable={true}
      data-test-subj="demoComboBox"
      isLoading={loading}
      fullWidth={true}
    />
  )
}
