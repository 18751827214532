import { gql } from "@apollo/client";

const REMOVE_FILE = gql`
  mutation ($file: String!) {
    removeFile(file: $file) {
     success
   }
  }
`;

export default REMOVE_FILE;
