import React, { useState, Fragment } from "react";

import {
  EuiForm,
  EuiButton,
  EuiFormRow,
  EuiFieldText,
  EuiBreadcrumbs,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiToast,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";

import { useMutation } from "@apollo/client";
import { navigate } from "@reach/router";

import MealTagsComboBox from "../components/MealTagsComboBox";
import AuthorSelect from "../components/AuthorSelect";
import ListFieldText from "../components/ListFieldText";

import CREATE_RECIPE from "../mutations/createRecipe";

export default function RecipeCreate() {
  const [displayToast, setDisplayToast] = useState(false);
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");

  const [mealTags, setMealTags] = useState([]);
  const [ingLines, setIngLines] = useState([""]);
  const [instructions, setInstructions] = useState([""]);
  const [numberOfServings, setNumberOfServings] = useState(1);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(60);
  const [externalId, setExternalId] = useState("");

  const [createRecipe, { loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_RECIPE, {
      errorPolicy: "all",
      onCompleted(data) {
        if (data.createRecipe?.databaseId) {
          setDisplayToast(true);
          navigate(`/recipes/${data.createRecipe.databaseId}`);
        }
      },
      onError(error) {
        console.log(error);
      },
    });

  const _createRecipe = () => {
    let variables = {
      name: name,
      instructions: instructions,
      ingredientLines: ingLines,
      numberOfServings: numberOfServings,
      totalTimeInSeconds: totalTimeInSeconds,
      author: author,
      mealTags: mealTags.map(function (mt) {
        return mt.label;
      }),
    };
    if (externalId !== "") variables.externalId = externalId;
    createRecipe({ variables: variables });
  };

  const breadcrumbs = [
    {
      text: "Recipes",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        navigate(`/recipes`);
      },
    },
    {
      text: "Create",
    },
  ];

  const Toast = () => {
    return displayToast ? (
      <EuiToast
        title="Recipe was created"
        color="success"
        iconType="check"
        onClose={() => setDisplayToast(false)}
      ></EuiToast>
    ) : null;
  };

  return (
    <Fragment>
      {mutationError ? (
        <pre>
          Bad:{" "}
          {mutationError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      ) : null}
      <EuiBreadcrumbs
        breadcrumbs={breadcrumbs}
        truncate={false}
        aria-label="Breadcrumbs"
      />
      <EuiSpacer />
      <EuiPageContentHeaderSection>
        <EuiTitle size="s">
          <h1>Create Recipe</h1>
        </EuiTitle>
      </EuiPageContentHeaderSection>
      <EuiSpacer />

      <EuiForm component="form">
        <EuiFormRow label="Name" helpText="The name.">
          <EuiFieldText
            name="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </EuiFormRow>

        <EuiFormRow label="Author" helpText="Recipe Author.">
          <AuthorSelect item={author} setItem={setAuthor} />
        </EuiFormRow>

        <EuiFlexGroup style={{ maxWidth: 422, marginTop: 8, marginBottom: 8}}>
          <EuiFlexItem grow={true}>
            <EuiFormRow label="Number of Servings">
              <EuiFieldNumber
                placeholder="#"
                value={numberOfServings}
                onChange={(e) => setNumberOfServings(e.target.value)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Total Time In Seconds">
              <EuiFieldNumber
                placeholder=""
                value={totalTimeInSeconds}
                onChange={(e) => setTotalTimeInSeconds(e.target.value)}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiFormRow label="Meal Tags">
          <MealTagsComboBox setTags={setMealTags} currentTags={mealTags} />
        </EuiFormRow>

        <EuiFormRow label="Ingredient Lines">
          <ListFieldText
            list={ingLines}
            setList={setIngLines}
            addText="Add another"
            parse={true}
          />
        </EuiFormRow>

        <EuiFormRow label="Instructions">
          <ListFieldText
            list={instructions}
            setList={setInstructions}
            addText="Add another"
            textArea={true}
          />
        </EuiFormRow>

        <EuiFormRow label="External ID" helpText="Optional external reference identifier.">
          <EuiFieldText
            name="externalId"
            value={externalId}
            onChange={(e) => setExternalId(e.target.value)}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton fill onClick={_createRecipe} isLoading={mutationLoading}>
          Save
        </EuiButton>
      </EuiForm>

      <EuiSpacer />
      <Toast />
    </Fragment>
  );
}
