import React, { useState } from 'react';

import { EuiComboBox } from '@elastic/eui';
import { useQuery } from '@apollo/client';
import { gql } from "@apollo/client";

const ALL_INGREDIENTS = gql`
{
    allIngredients
}
`;

function IngredientsComboBox({ingredientsChanged}) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { loading, error, data } = useQuery(ALL_INGREDIENTS);

  if (error) return `Error! ${error}`;

  const options = []
  if (data){
    data.allIngredients.forEach(ing => options.push({ label: ing }));
  }

  function onChange(ings) {
    setSelectedOptions(ings)
    ingredientsChanged(ings)
  }

  return (

    <EuiComboBox
      placeholder="With ingredients"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      isClearable={true}
      data-test-subj="demoComboBox"
      isLoading={loading}
      fullWidth={true}
    />
  )
}
export default IngredientsComboBox;
