import dagre from "dagre";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const nodeQuestionWidth = 200;
export const nodeQuestionHeight = 400;

export const getLayoutedElements = (nodes, edges, direction = "LR") => {
  // LR: vertical layout
  // TB: horizontal layout
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    const isQuestion = "parentNode" in node;
    if (!isQuestion) {
      dagreGraph.setNode(node.id, {
        width: nodeQuestionWidth,
        height: nodeQuestionHeight,
      });
    }
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const isQuestion = "parentNode" in node;

    if (!isQuestion) {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeQuestionWidth / 2,
        y: nodeWithPosition.y - nodeQuestionHeight / 2,
      };
    }

    return node;
  });

  return { nodes, edges };
};
