import React from "react";

import {
  EuiBasicTable,
  EuiHealth,
  EuiProgress,
  EuiImage,
  EuiButtonIcon,
} from "@elastic/eui";

import { useQuery } from "@apollo/client";

import RecipeFlyout from "../components/RecipeFlyout";
import GET_RECIPES from "../querys/getRecipes";

function RecipesList({
  query,
  isActive,
  isPremium,
  isPurchasable,
  hasImage,
  useMatchPhrase,
  caloriesRange,
  proteinRange,
  fatRange,
  ingredientRange,
  carbsRange,
  omega3Range,
  totalTimeRange,
  size,
  meals,
  hasKnownIngredients,
  mustIngredients,
  tags,
  courses,
  author,
  source,
  restrictions,
  sourceUrlWorks,
}) {
  const columns = [
    {
      field: "node.mainImage",
      name: "Main Image",
      render: (mainImage) => {
        return mainImage ? (
          <EuiImage
            size="s"
            allowFullScreen
            caption=""
            alt=""
            url={mainImage}
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "node",
      name: "Macros",
      render: (node) => {
        let max =
          node.caloriesPerServing.protein +
          node.caloriesPerServing.carbs +
          node.caloriesPerServing.fat;
        return node.nutritionalInfo ? (
          <ul>
            <li>
              <b>Protein:</b>{" "}
              {Math.round((node.caloriesPerServing.protein * 100) / max)}%{" "}
              <EuiProgress
                value={Math.round(node.caloriesPerServing.protein)}
                max={max}
                color="primary"
                size="xs"
              />{" "}
            </li>
            <li>
              <b>Carbs:</b>{" "}
              {Math.round((node.caloriesPerServing.carbs * 100) / max)}%{" "}
              <EuiProgress
                value={Math.round(node.caloriesPerServing.carbs)}
                max={max}
                color="accent"
                size="xs"
              />{" "}
            </li>
            <li>
              <b>Fat:</b>{" "}
              {Math.round((node.caloriesPerServing.fat * 100) / max)}%{" "}
              <EuiProgress
                value={Math.round(node.caloriesPerServing.fat)}
                max={max}
                color="secondary"
                size="xs"
              />{" "}
            </li>
          </ul>
        ) : (
          `-`
        );
      },
    },

    {
      field: "node.nutrientsPerServing.calories",
      name: "Calories per serving",
      sortable: true,
      render: (calories) => {
        const cal = Math.round(calories);
        return `${cal} kcal`;
      },
    },
    {
      field: "node.caloriesPerServing",
      name: "Omega3",
      render: (caloriesPerServing) => {
        const omegas = Math.round(
          caloriesPerServing.dha +
            caloriesPerServing.dpa +
            caloriesPerServing.epa +
            caloriesPerServing.ala
        );
        return `${omegas}`;
      },
    },
    {
      field: "node.servingWeight",
      name: "Serving Weight",
      sortable: true,
      render: (servingWeight) => {
        const cal = Math.round(servingWeight);
        return `${cal} gm`;
      },
    },
    {
      field: "node.numberOfServings",
      name: "# Servings",
    },
    {
      field: "node.name",
      name: "Name",
      sortable: true,
    },
    {
      field: "node.author",
      name: "Author",
      truncateText: true,
    },
    {
      field: "node",
      name: "Created at",
      render: (item) => {
        return item.indexedAt;
      },
    },
    {
      field: "node.isActive",
      name: "Is Active",
      dataType: "boolean",
      render: (isActive) => {
        const color = isActive ? "success" : "danger";
        return <EuiHealth color={color}></EuiHealth>;
      },
    },
    {
      field: "node.isPremium",
      name: "Is Premium",
      dataType: "boolean",
      render: (isPremium) => {
        const color = isPremium ? "success" : "danger";
        return <EuiHealth color={color}></EuiHealth>;
      },
    },
    {
      field: "node",
      name: "Quick View",
      render: (item) => {
        return <RecipeFlyout item={item} />;
      },
    },
    {
      field: "node",
      name: "Detail",
      render: (recipe) => {
        return (
          <EuiButtonIcon
            href={`/recipes/${recipe.databaseId}`}
            iconType="bullseye"
            target="_blank"
          ></EuiButtonIcon>
        );
      },
    },
  ];

  const getRowProps = (item) => {
    const { databaseId } = item.node;
    return {
      "data-test-subj": `row-${databaseId}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${databaseId}`),
    };
  };

  const getCellProps = (item, column) => {
    const { databaseId } = item.node;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${databaseId}-${field}`,
      textOnly: true,
    };
  };

  const { loading, error, data } = useQuery(GET_RECIPES, {
    variables: {
      query: query,
      first: size,
      isPremium: isPremium,
      isPurchasable: isPurchasable,
      isActive: isActive,
      sourceUrlWorks: sourceUrlWorks,
      hasImage: hasImage,
      useMatchPhrase: useMatchPhrase,
      caloriesRange: caloriesRange,
      proteinRange: proteinRange,
      fatRange: fatRange,
      carbsRange: carbsRange,
      omega3Range: omega3Range,
      ingredientRange: ingredientRange,
      totalTimeRange: totalTimeRange,
      meals: meals
        .filter((meal) => meal.checked === "on")
        .map(function (meal) {
          return meal.key;
        }),
      hasKnownIngredients: hasKnownIngredients,
      mustIngredients: mustIngredients.map(function (ingredient) {
        return ingredient.label;
      }),
      tags: tags.map(function (i) {
        return i.label;
      }),
      courses: courses.map(function (i) {
        return i.label;
      }),
      author: author,
      source: source,
      restrictions: restrictions.map(function (i) {
        return i.label;
      }),
    },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );
  if (error) return `Error! ${error}`;

  return (
    <EuiBasicTable
      items={data.recipes.edges}
      rowHeader="firstName"
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps}
    />
  );
}

export default RecipesList;
