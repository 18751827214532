import React, { useState } from "react";

import {
  EuiBasicTable,
  EuiProgress,
  EuiButton,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";

import IngredientFlyout from "./IngredientFlyout";
import IngredientMultiEditFlyout from "./IngredientMultiEditFlyout";
import INGREDIENTS from "../querys/ingredients";
import { useQuery } from "@apollo/client";

export default function IngredientResults({ query }) {
  const [isFlyoutEditVisible, toggleIsFlyoutEditVisible] = useState(false);
  const [flyoutEditId, setFlyoutEditId] = useState(null);
  const [isMultiEditFlyoutEditVisible, toggleMultiEditIsFlyoutEditVisible] =
    useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [cursor, setCursor] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("desc");
  const { loading, error, data } = useQuery(INGREDIENTS, {
    variables: { first: 20, query: query, after: cursor },
  });
  let flyout;
  let multiEditflyout;

  const actions = [
    {
      name: "Edit",
      description: "Quick edit",
      icon: "documentEdit",
      type: "icon",
      onClick: (item) => {
        toggleIsFlyoutEditVisible(!isFlyoutEditVisible);
        setFlyoutEditId(item.id);
      },
    },
  ];

  const columns = [
    {
      field: "name",
      name: "Name",
      sortable: true,
      render: (name) => {
        return <b>{name}</b>;
      },
    },
    {
      field: "alias",
      name: "Alias",
    },
    {
      field: "aisle.name",
      name: "Aisle",
    },
    {
      name: "Actions",
      actions,
    },
  ];

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    const { field: sortField, direction: sortDirection } = sort;
    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);

    const cursorIndex = pageIndex * pageSize - 1;
    if (cursorIndex > 1) {
      setCursor(btoa(`arrayconnection:${cursorIndex}`));
    } else {
      setCursor(btoa(`arrayconnection:0`));
    }
  };

  const close = () => {
    toggleIsFlyoutEditVisible(false);
  };

  const closeMultiEdit = () => {
    toggleMultiEditIsFlyoutEditVisible(false);
  };

  if (isFlyoutEditVisible) {
    flyout = (
      <IngredientFlyout
        close={close}
        id={flyoutEditId}
        query={{ query: query, after: cursor, first: 20 }}
      />
    );
  }

  if (isMultiEditFlyoutEditVisible) {
    multiEditflyout = (
      <IngredientMultiEditFlyout
        close={closeMultiEdit}
        selectedItems={selectedItems}
        query={{ query: query, after: cursor, first: 20 }}
      />
    );
  }

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );
  if (error) return `Error! ${error}`;

  const totalItemCount = data.ingredients.count;

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    hidePerPageOptions: true,
  };

  const onSelectionChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const selection = {
    onSelectionChange: onSelectionChange,
  };

  const renderDeleteButton = () => {
    if (selectedItems.length === 0) {
      return;
    }

    return (
      <EuiButton
        iconType="documentEdit"
        onClick={() => {
          toggleMultiEditIsFlyoutEditVisible(true);
        }}
      >
        Update {selectedItems.length} Ingredients
      </EuiButton>
    );
  };

  const deleteButton = renderDeleteButton();

  const items = data.ingredients.edges.map(function (node) {
    return node.node;
  });

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
    readOnly: true,
  };

  return (
    <>
      <EuiSpacer size="l" />

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}></EuiFlexItem>
        <EuiFlexItem />
        {deleteButton}
      </EuiFlexGroup>

      <EuiSpacer size="l" />

      <EuiBasicTable
        items={items}
        itemId="id"
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        isSelectable={true}
        selection={selection}
        onChange={onTableChange}
        rowHeader="name"
      />
      {flyout}
      {multiEditflyout}
    </>
  );
}
