import React, { Fragment } from "react";

import {
  EuiBasicTable,
  EuiAccordion,
  EuiImage,
  EuiBadge,
  EuiI18nNumber,
  EuiSpacer,
  EuiButtonIcon,
} from "@elastic/eui";

function MealPlanDebugMode({ data }) {
  const columns = [
    {
      field: "mainImage",
      name: "Main Image",
      render: (mainImage) => {
        return mainImage ? (
          <EuiImage
            size="s"
            allowFullScreen
            caption=""
            alt=""
            url={mainImage}
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "name",
      name: "Recipe",
      render: (name) => {
        return <b>{name}</b>;
      },
    },
    {
      field: "mealTag",
      name: "Meal",
      render: (mealTag) => {
        return `${mealTag}`;
      },
    },
    {
      field: "calories",
      name: "Total Meal Calories",
      render: (calories) => {
        return <b>{Math.round(calories)}</b>;
      },
    },
    {
      name: "Real total calories",
      render: (item) => {
        return Math.round(item.fat + item.protein + item.carbs + item.omega3);
      },
    },
    {
      field: "numberOfServings",
      name: "# of Servings",
      render: (numberOfServings) => {
        return `${numberOfServings}`;
      },
    },
    {
      field: "calories",
      name: "Calories per serving",
      render: (calories) => {
        return `${Math.round(calories)}`;
      },
    },
    {
      field: "carbs",
      name: "Carbs per serving",
      render: (carbs) => {
        return `${Math.round(carbs)}`;
      },
    },
    {
      field: "protein",
      name: "Protein per serving",
      render: (protein) => {
        return `${Math.round(protein)}`;
      },
    },
    {
      field: "fat",
      name: "Fat per serving",
      render: (fat) => {
        return `${Math.round(fat)}`;
      },
    },
    {
      field: "omega3",
      name: "Omega 3",
      render: (omega3) => {
        return `${omega3.toFixed(2)}`;
      },
    },
    {
      field: "id",
      name: "Detail",
      render: (id) => {
        return (
          <EuiButtonIcon
            href={`/recipes/${id}`}
            iconType="bullseye"
            target="_blank"
            aria-label="recipeBulleye"
          ></EuiButtonIcon>
        );
      },
    },
  ];

  const getRowProps = (item) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${id}`),
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  const add = (a, b) => a + b.calories;

  const buttonContent = (item, i) => {
    const sum = item.reduce(add, 0);
    return (
      <div>
        {`Day ${i + 1} – `}
        <EuiBadge color="hollow">
          <EuiI18nNumber value={Math.round(sum)} />
        </EuiBadge>
      </div>
    );
  };

  const planDays = data.mealPlanDebug.meals.map((item, i) => (
    <Fragment key={`${i}-fragment`}>
      <EuiAccordion
        id={`${i}-accordion`}
        buttonContent={buttonContent(item, i)}
        initialIsOpen={true}
      >
        <EuiBasicTable
          items={item}
          rowHeader="firstName"
          columns={columns}
          rowProps={getRowProps}
          cellProps={getCellProps}
        />
      </EuiAccordion>
      <EuiSpacer size="xl" />
    </Fragment>
  ));

  return <>{planDays}</>;
}

export default MealPlanDebugMode;
