import React, { useState } from "react";
import {
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiTextArea,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButtonIcon,
  EuiConfirmModal,
} from "@elastic/eui";
import AislesSelect from "./AisleSelect";
import IngredientGroupsComboBox from "./IngredientGroupsComboBox";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import INGREDIENTS from "../querys/ingredients";

const UPDATE_INGREDIENT = gql`
  mutation updateIngredient(
    $id: ID!
    $aisle: String
    $alias: [String]
    $ingredientGroups: [ID]
  ) {
    updateIngredient(
      id: $id
      aisle: $aisle
      alias: $alias
      ingredientGroups: $ingredientGroups
    ) {
      success
    }
  }
`;

const DELETE_INGREDIENT = gql`
  mutation deleteIngredient($id: ID!) {
    deleteIngredient(id: $id) {
      success
    }
  }
`;

export default function IngredientEditFlyout({ data, query, close }) {
  const [aisle, setAisle] = useState(
    data.ingredient?.aisle ? data.ingredient.aisle.id : null
  );
  const [ingredientGroups, setIngredientGroups] = useState(
    data.ingredient.ingredientgroupSet.edges.map((e) => ({
      value: e.node.id,
      label: e.node.name,
      key: e.node.id
    }))
  );
  const [alias, setAlias] = useState(
    data.ingredient?.alias ? data.ingredient.alias : []
  );
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);

  const [updateIngredient, { loading, error }] = useMutation(
    UPDATE_INGREDIENT,
    {
      errorPolicy: "all",
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const [deleteIngredient, { deleteLoading, deleteError }] = useMutation(
    DELETE_INGREDIENT,
    {
      errorPolicy: "all",
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const _update = () => {
    let variables = { id: data.ingredient.id };
    if (data.ingredient.aisle !== aisle) variables.aisle = aisle;
    if (data.ingredient.alias !== alias) variables.alias = alias.split(",");

    // Only send ingredient group ids if different
    let ingGroups = data.ingredient.ingredientgroupSet.edges.map((e) => ({
      value: e.node.id,
      label: e.node.name,
    }));
    if (JSON.stringify(ingGroups) !== JSON.stringify(ingredientGroups))
      variables.ingredientGroups = ingredientGroups.map((t) => t.value);

    updateIngredient({
      variables: variables,
      refetchQueries: [
        {
          query: INGREDIENTS,
          variables: {
            query: query.query,
            first: query.first,
            after: query.after,
          },
        },
      ],
    });
    close();
  };

  const _delete = () => {
    let variables = { id: data.ingredient.id };
    deleteIngredient({
      variables: variables,
      refetchQueries: [
        {
          query: INGREDIENTS,
          variables: {
            query: query.query,
            first: query.first,
            after: query.after,
          },
        },
      ],
    });
    close();
  };

  let destroyModal;

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiConfirmModal
        title="Delete Ingredient"
        onCancel={() => setIsDestroyModalVisible(false)}
        onConfirm={_delete}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          You're about to delete the ingredient: <b>{data.ingredient.name}</b>
        </p>
        <p>Are you sure you want to do this?</p>
      </EuiConfirmModal>
    );
  }

  if (error)
    return (
      <pre>
        Error:{" "}
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );
  if (deleteError)
    return (
      <pre>
        Error:{" "}
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  return (
    <>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 id="flyoutTitle">{data.ingredient.name}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm component="form">
          <EuiFormRow
            label="Alias"
            helpText="Other representation of the same ingredient, each alias should be separated by comma"
          >
            <EuiTextArea
              placeholder=""
              aria-label=""
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Aisle" helpText="Aisle used in shopping list">
            <AislesSelect
              value={aisle}
              onChange={(e) => setAisle(e.target.value)}
              name={"aisle"}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Ingrediet Groups"
            helpText="Groups where this ingredient belongs."
          >
            <IngredientGroupsComboBox
              currentGroups={ingredientGroups}
              setGroups={(value) => setIngredientGroups(value)}
            />
          </EuiFormRow>

          <EuiSpacer />

          <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            responsive={false}
            wrap
          >
            <EuiFlexItem grow={false}>
              <EuiButton
                fill
                onClick={_update}
                isLoading={loading}
                disabled={loading}
              >
                Save
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                color="danger"
                iconType="trash"
                aria-label="delete ingredient"
                onClick={() => setIsDestroyModalVisible(true)}
                disabled={deleteLoading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiFlyoutBody>
      {destroyModal}
    </>
  );
}
