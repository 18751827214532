import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";

import RECIPE_PROGRAM_DEBUG from "../querys/recipeProgramDebugger";

import {
  EuiProgress,
  EuiTitle,
  EuiSpacer,
  EuiHealth,
  EuiDescriptionList,
  EuiFlexItem,
  EuiFlexGroup,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiText,
  EuiBasicTable,
} from "@elastic/eui";

function KnownIngredients({ known, unknown }) {
  const knownItems = known.map((i, k) => (
    <li key={`knownIng-${k}`}>
      <EuiText color="secondary">{i}</EuiText>
    </li>
  ));
  const unknownItems = unknown.map((i, k) => (
    <li key={`unknownIng-${k}`}>
      <EuiText color="danger">{i}</EuiText>
    </li>
  ));
  return (
    <ul>
      {knownItems}
      {unknownItems}
    </ul>
  );
}

function RecipeProgramDebug({ recipeId, programId }) {
  const id = btoa(`Recipe:${recipeId}`);

  const { loading, error, data } = useQuery(RECIPE_PROGRAM_DEBUG, {
    variables: { recipeId: recipeId, programId: programId },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const getRowProps = (item) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  const columns = [
    {
      field: "ingredients",
      name: "Ingredients",
    },
    {
      field: "cpc",
      name: "CPC",
    },
    {
      field: "ingredientGroup",
      name: "Ingredient Group",
    },
    {
      field: "actionType",
      name: "Action Type",
    },
  ];

  return (
    <Fragment>
      <EuiTitle size="l">
        <h1>
          {data.recipeProgramDebug.recipe.name} -{" "}
          {data.recipeProgramDebug.program.name}
        </h1>
      </EuiTitle>
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiDescriptionList>
            <EuiDescriptionListTitle>Is Active</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiHealth
                color={
                  data.recipeProgramDebug.recipe.isActive ? "success" : "danger"
                }
              ></EuiHealth>
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>
              Has known ingredients
            </EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <EuiHealth
                color={
                  data.recipeProgramDebug.recipe.hasKnownIngredients
                    ? "success"
                    : "danger"
                }
              ></EuiHealth>
            </EuiDescriptionListDescription>

            <EuiDescriptionListTitle>Ingredients:</EuiDescriptionListTitle>
            <EuiDescriptionListDescription>
              <KnownIngredients
                known={data.recipeProgramDebug.recipe.knownIngredients.known}
                unknown={
                  data.recipeProgramDebug.recipe.knownIngredients.unknown
                }
              />
            </EuiDescriptionListDescription>
          </EuiDescriptionList>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiBasicTable
            items={data.recipeProgramDebug.debug}
            rowHeader="rowHeaderDebug"
            columns={columns}
            rowProps={getRowProps}
            cellProps={getCellProps}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      {id}
    </Fragment>
  );
}

export default RecipeProgramDebug;
