import React from "react";
import { useQuery, gql } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";

import QuestionForm from "./QuestionForm";
import AnswerForm from "./AnswerForm";

const QUESTION_QUERY = gql`
  query question($id: ID!) {
    question(id: $id) {
      id
      text
      questionType
      canSkip
      isInitialQuestion
      answers {
        edges {
          node {
            id
            text
          }
        }
      }
      nextQuestion {
        id
        text
        questionType
        canSkip
      }
    }
  }
`;

export default function NodeFlyout({
  node,
  isFlyoutVisible,
  toggleIsFlyoutVisible,
  setNodes,
}) {
  let flyout;
  const { loading, error, data } = useQuery(QUESTION_QUERY, {
    variables: { id: node.id },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  if (data && isFlyoutVisible) {
    flyout =
      node.data.nodeType === "Question" ? (
        <QuestionForm
          node={data.question}
          toggleIsFlyoutVisible={toggleIsFlyoutVisible}
          setNodes={setNodes}
        />
      ) : (
        <AnswerForm
          node={data.question}
          isFlyoutVisible={isFlyoutVisible}
          toggleIsFlyoutVisible={toggleIsFlyoutVisible}
        />
      );
  }

  return <div>{flyout}</div>;
}
