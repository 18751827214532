import React, { Fragment } from "react";

import {
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
} from "@elastic/eui";

function Recipes({ viewer }) {
  return (
    <Fragment>
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle>
            <h2>Home</h2>
          </EuiTitle>
        </EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPageContentBody>
        <h3> <span role="img" aria-label="hi">👋</span> Welcome {viewer.email}</h3>
      </EuiPageContentBody>
    </Fragment>
  );
}

export default Recipes;
