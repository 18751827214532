import { gql } from "@apollo/client";

const RECIPE_TRANSLATION_DETAIL = gql`
  query recipe($id: ID!) {
    recipe(id: $id) {
      id
      databaseId
      name
      ingredientLines
      ingredients
      instructions
    }
  }
`;

export default RECIPE_TRANSLATION_DETAIL;
