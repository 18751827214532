import React from "react";
import { useQuery } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";

import ChangeRecipeTranslationForm from "../components/ChangeRecipeTranslationForm";
import TRANSLATION_RECIPE_DETAIL from "../querys/recipeTranslationDetail";

export default function ChangeRecipeTranslation({ recipeId }) {
  const id = btoa(`Recipe:${recipeId}`);
  const { loading, error, data } = useQuery(TRANSLATION_RECIPE_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return <ChangeRecipeTranslationForm recipe={data.recipe} />;
}
