import { gql } from "@apollo/client";

const UPDATE_RECIPE = gql`
  mutation updateRecipe(
    $id: ID!
    $name: String
    $author: String
    $isActive: Boolean
    $sgValidated: Boolean
    $tags: [String]
    $mealTags: [String]
    $cuisines: [String]
    $ingredientLines: [String]
    $instructions: [String]
    $numberOfServings: Int
    $totalTimeInSeconds: Int
    $totalTime: String
    $mainImage: String
    $isPurchasable: Boolean
    $sourceUrlWorks: Boolean
    $notGoodOptionForMealPlan: Boolean
  ) {
    updateRecipe(
      id: $id
      name: $name
      author: $author
      isActive: $isActive
      sgValidated: $sgValidated
      isPurchasable: $isPurchasable
      tags: $tags
      mealTags: $mealTags
      cuisines: $cuisines
      ingredientLines: $ingredientLines
      instructions: $instructions
      numberOfServings: $numberOfServings
      totalTimeInSeconds: $totalTimeInSeconds
      totalTime: $totalTime
      mainImage: $mainImage
      sourceUrlWorks: $sourceUrlWorks
      notGoodOptionForMealPlan: $notGoodOptionForMealPlan
    ) {
      id
    }
  }
`;

export default UPDATE_RECIPE;
