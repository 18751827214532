import React, { useState } from "react";

import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiNotificationBadge,
  EuiText,
  EuiSpacer,
  EuiButtonEmpty,
  EuiPopover,
  EuiSelect,
} from "@elastic/eui";

import { isLoggedInVar } from "../cache";
import { useMutation } from "@apollo/client";
import UPDATE_USER_LANGUAGE from "../mutations/updateUserLanguage";

function LanguageSelect({ viewer }) {
  const options = [
    { value: "EN", text: "English " },
    { value: "ES", text: "Spanish " },
    { value: "NL", text: "Dutch" },
    { value: "AR", text: "Arabic" },
    { value: "EN_GB", text: "English (United Kingdom)" },
  ];

  const [value, setValue] = useState(viewer.language ? viewer.language : "EN");
  const [updateUserLanguage, { loading }] = useMutation(UPDATE_USER_LANGUAGE, {
    errorPolicy: "all",
    onCompleted(data) {
      window.location.reload();
    },
    onError(error) {
      console.log(error);
    },
  });

  const onChange = (e) => {
    updateUserLanguage({ variables: { language: e.target.value } });
    setValue(e.target.value);
  };

  return (
    <EuiSelect
      id="selectLang"
      options={options}
      value={value}
      onChange={(e) => onChange(e)}
      aria-label="Language selection"
      disabled={loading}
    />
  );
}

export default function UserMenu({ viewer }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function logout() {
    localStorage.removeItem("token");
    isLoggedInVar(false);
  }

  const button = (viewer) => {
    return (
      <EuiHeaderSectionItemButton
        aria-controls="headerUserMenu"
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Account menu"
        onClick={toggle}
      >
        <EuiAvatar name={viewer.email} size="s" />

        <EuiNotificationBadge className="euiHeaderNotification">
          1
        </EuiNotificationBadge>
      </EuiHeaderSectionItemButton>
    );
  };

  return (
    <EuiPopover
      id="headerUserMenu"
      ownFocus
      button={button(viewer)}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={toggle}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={viewer.email} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{viewer.email}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty onClick={logout}>Log out</EuiButtonEmpty>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <LanguageSelect viewer={viewer} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>

        {/* <EuiHeaderAlert
          title="Here's a notification title"
          text="I am the hat judge. Show me a hat and I will tell you if it&rsquo;s a good hat or bad hat."
          date="Nov. 14, 02:14PM."
        /> */}
      </div>
    </EuiPopover>
  );
}
