import { gql } from "@apollo/client";

const UPDATE_GPT_INGREDIENT = gql`
  mutation gptIngredientUpdate($input: GPTIngredientUpdateInput!) {
    gptIngredientUpdate(input: $input) {
      success
      message
      gptIngredient {
        id
        name
        matchId
        quantity
        grams
        matchId
        matchType
        matchMetadata
      }
    }
  }
`;
export default UPDATE_GPT_INGREDIENT