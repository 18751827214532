import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiButtonIcon,
  EuiFormRow,
  EuiFieldNumber,
  EuiButton,
  EuiSelect,
} from "@elastic/eui";

import INGREDIENT_SEARCH from "../querys/IngredientSearch";
import UPDATE_GPT_INGREDIENT from "../mutations/updateGPTIngredient";

function IngredientSelect({ item, setItem, searchFor }) {
  const { loading, error, data } = useQuery(INGREDIENT_SEARCH, {
    variables: { query: searchFor },
  });

  if (error) return `Error! ${error}`;

  let options = [];

  if (data) {
    data.ingredientSearch.forEach((i) =>
      options.push({ value: i.id, text: i.source.description, index: i.index })
    );
  }

  const setNewMatch = (value) => {
    let selectedMatch = options.filter((o) => o.value === value);
    setItem(selectedMatch[0]);
  };

  return (
    <EuiSelect
      id="selectDocExample"
      options={options}
      value={item.matchId}
      onChange={(e) => setNewMatch(e.target.value)}
      isLoading={loading}
    />
  );
}

function IngredientEdit({ item }) {
  const [grams, setGrams] = useState(item.grams);
  const [quantity, setQuantity] = useState(item.quantity);
  const [match, setMatch] = useState(item);
  const [updateIngredient, { loading }] = useMutation(
    UPDATE_GPT_INGREDIENT,
    {
      errorPolicy: "all",
      onCompleted(data) {},
      onError(error) {
        console.log(error);
      },
    }
  );

  const _updateIngredient = () => {
    let input = { id: item.id };
    if (item.grams !== grams) input.grams = grams;
    if (item.quantity !== quantity) input.quantity = quantity;
    if (item.matchId !== match.value) {
      input.matchId = match.value;
      input.matchType = match.index;
    }
    updateIngredient({ variables: { input: input } });
  };

  return (
    <EuiFlexGroup style={{ maxWidth: 600 }}>
      <EuiFlexItem>
        <EuiFormRow label="Ingredient">
          <IngredientSelect
            item={match}
            setItem={setMatch}
            searchFor={item.name}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: 100 }}>
        <EuiFormRow label="Quantity">
          <EuiFieldNumber
            max={100}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: 100 }}>
        <EuiFormRow label="Grams">
          <EuiFieldNumber
            max={1000}
            value={grams}
            onChange={(e) => setGrams(e.target.value)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow hasEmptyLabelSpace display="center">
          <EuiButton onClick={_updateIngredient} isLoading={loading}>
            Update
          </EuiButton>
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

function IngredientList({ item }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <EuiPanel paddingSize="l" hasBorder>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            {isEditing ? (
              <IngredientEdit item={item} />
            ) : (
              <EuiText>
                <h4>
                  "{item.quantity} {item.name}"
                </h4>
                <small>
                  <EuiTextColor color="subdued">{item.grams}g</EuiTextColor>
                </small>
                <EuiTextColor color="default">
                  <b> | </b>
                </EuiTextColor>
                <small>
                  <EuiTextColor color="subdued">
                    {item.matchMetadata.description}
                  </EuiTextColor>
                </small>
              </EuiText>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {isEditing ? (
              <EuiButtonIcon
                onClick={() => {
                  setIsEditing(false);
                }}
                iconType="cross"
                aria-label="cross"
              />
            ) : (
              <EuiButtonIcon
                onClick={() => {
                  setIsEditing(true);
                }}
                iconType="documentEdit"
                aria-label="documentEdit"
              />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer size="s" />
    </>
  );
}

function AssistantIngredients({ list }) {
  const ingredients = list.map(({ node: item }, i) => {
    return <IngredientList item={item} key={`il-${i}`} />;
  });

  return <>{ingredients}</>;
}

export default AssistantIngredients;
