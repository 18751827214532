import React, { Fragment, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  EuiTitle,
  EuiSpacer,
  EuiProgress,
  EuiText,
  EuiButton,
  EuiSelect,
  EuiDatePicker,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiCallOut,
} from "@elastic/eui";
import moment from "moment";

import AssistantIngredients from "../components/AssistantIngredients";
import ASSISTANT_MESSAGE from "../querys/AssistantMessage";
import CONFIRM_FOOD_LOG_ASSISTANT from "../mutations/confirmFoodLogAssistant";

function FoodLogAssistant({ id }) {
  const { loading, error, data } = useQuery(ASSISTANT_MESSAGE, {
    variables: { id: id },
  });
  const [createFoodLog, { loading: mLoading }] = useMutation(
    CONFIRM_FOOD_LOG_ASSISTANT,
    {
      errorPolicy: "all",
      onCompleted(data) {},
      onError(error) {
        console.log(error);
      },
    }
  );

  const [mealTime, setMealTime] = useState("");
  const [date, setDate] = useState(moment());

  const mealOptions = [
    { value: "BREAKFAST", text: "Breakfast" },
    { value: "LUNCH", text: "Lunch" },
    { value: "DINNER", text: "Dinner" },
    { value: "SNACK", text: "Snack" },
  ];

  useEffect(() => {
    if (loading) {
      return;
    } else {
      setMealTime(data?.assistantMessage.mealTime);
      var _utcDate = moment.utc(data.assistantMessage.createdAt).toDate();
      setDate(moment(_utcDate).local());
    }
  }, [loading, data]);

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  var utcDate = moment.utc(data.assistantMessage.createdAt).toDate();
  var timestamp = moment(utcDate).local().fromNow();

  const _createFoodLog = () => {
    let input = {
      id: id,
      mealTime: mealTime,
      date: date.format("YYYY-MM-DD"),
    };
    createFoodLog({ variables: { input: input } });
  };

  return (
    <Fragment>
      <EuiTitle size="l">
        <h1>Food log assistant 🤖 </h1>
      </EuiTitle>
      <small>Message created at: {timestamp}</small>

      {data.assistantMessage.isLogged && (
        <EuiCallOut
          title="This food is already logged!"
          color="success"
          iconType="check"
        ></EuiCallOut>
      )}

      <EuiSpacer />

      <EuiText size="s">
        <p>User Input:</p>
        <blockquote>{data?.assistantMessage.message}</blockquote>
      </EuiText>

      <EuiSpacer />

      <EuiFlexGroup style={{ maxWidth: 800 }}>
        <EuiFlexItem>
          <EuiFormRow label="Food name" helpText="Auto generated name by GPT">
            <EuiFieldText
              value={data?.assistantMessage.metadata?.title}
              disabled={true}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Date" helpText="Log food on this date">
            <EuiDatePicker
              selected={date}
              onChange={(d) => setDate(d)}
              disabled={data.assistantMessage.isLogged}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Meal time">
            <EuiSelect
              options={mealOptions}
              value={mealTime}
              onChange={(e) => setMealTime(e.target.value)}
              disabled={data.assistantMessage.isLogged}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <AssistantIngredients
        list={data.assistantMessage.gptingredientSet.edges}
      />

      <EuiSpacer />

      <EuiButton
        fill
        onClick={_createFoodLog}
        isLoading={mLoading}
        disabled={data.assistantMessage.isLogged}
      >
        Log Food
      </EuiButton>
    </Fragment>
  );
}

export default FoodLogAssistant;
