import React, { Fragment, useState } from 'react';

import {
  EuiFlexItem,
  EuiFieldSearch
} from '@elastic/eui';

import Meals from '../components/Meals';

function MealSuggest({addMeal}) {

  const [query, setQuery] = useState("");
  
  function clear() {
    setQuery("");
  }

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFieldSearch
          placeholder="Search..."
          fullWidth
          value={query}
          incremental={false}
          onChange={e => setQuery(e.target.value)}
        />
      </EuiFlexItem>
      { query ? <Meals query={query} addMeal={addMeal} clear={clear}/> : ''}
    </Fragment>
  );
}

export default MealSuggest