import React from "react";
import { useQuery } from "@apollo/client";
import { EuiProgress } from "@elastic/eui";

import ChangeRecipeForm from "../components/ChangeRecipeForm";
import RECIPE_DETAIL from "../querys/recipeDetail";

function ChangeRecipe({ recipeId }) {
  const id = btoa(`Recipe:${recipeId}`);
  const { loading, error, data } = useQuery(RECIPE_DETAIL, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  return <ChangeRecipeForm recipe={data.recipe} />;
}

export default ChangeRecipe;
