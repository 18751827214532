import React, { useState } from "react";

import { EuiFieldSearch, EuiProgress } from "@elastic/eui";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import RecipeGlobalSearchResults from "../components/RecipeGlobalSearchResults";
import GenericFoodsGSearchResults from "../components/GenericFoodsGSearchResults";
import ProductsGlobalSearchResults from "../components/ProductsGlobalSearchResults";

const GLOBAL_SEARCH = gql`
  fragment NutrientsPerServingFragment on NutrientsPerServing {
    calories
    fat
    protein
    carbs
  }

  query GlobalSearch($query: String, $first: Int) {
    recipes: recipes(query: $query, first: $first, isActive:true) {
      edges {
        node {
          id
          name
          mainImage
          author
          nutrientsPerServing {
            ...NutrientsPerServingFragment
          }
        }
      }
    }
    genericFoods: genericFoods(query: $query, first: $first) {
      edges {
        node {
          id
          name
          brand
          image
          defaultMeasure
          nutrientsPerServing {
            ...NutrientsPerServingFragment
          }
        }
      }
    }
    products: products(query: $query, first: $first) {
      edges {
        node {
          id
          name
          brand
          image
          defaultMeasure
          nutrientsPerServing {
            ...NutrientsPerServingFragment
          }
        }
      }
    }
  }
`;

export default function GlobalSearch() {
  const [query, setQuery] = useState("");

  const [globalSearch, { loading, data }] = useLazyQuery(GLOBAL_SEARCH);

  const search = () => {
    globalSearch({ variables: { query: query, first: 6 } });
  };

  let genericFoods;
  let products;
  let recipes;

  if (data?.genericFoods && data?.genericFoods.edges.length) {
    genericFoods = (
      <GenericFoodsGSearchResults items={data.genericFoods.edges} />
    );
  }
  if (data?.products && data?.products.edges.length) {
    products = (
      <ProductsGlobalSearchResults items={data.products.edges} />
    );
  }
  if (data?.recipes && data?.recipes.edges.length) {
    recipes = (
      <RecipeGlobalSearchResults items={data.recipes.edges} />
    );
  }

  return (
    <>
      <EuiFieldSearch
        placeholder="Search..."
        fullWidth
        value={query}
        incremental={false}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={() => search()}
      />

      {loading ? <EuiProgress size="xs" color="accent" /> : null}

      {genericFoods}
      {products}
      {recipes}

    </>
  );
}
