import { gql } from "@apollo/client";

const RECIPE_CREATE = gql`
mutation CreateRecipe(
    $name: String!
    $instructions: [String]!
    $ingredientLines: [String]!
    $numberOfServings: Int!
    $totalTimeInSeconds: Int!
    $author: String!
    $mealTags: [MealTag]!
    $externalId: String
  ){
    createRecipe(
      name: $name
      instructions: $instructions
      ingredientLines:$ingredientLines
      numberOfServings: $numberOfServings
      totalTimeInSeconds: $totalTimeInSeconds
      author: $author
      mealTags: $mealTags
      externalId: $externalId
    ) {
      id
      databaseId
    }
  }

`;

export default RECIPE_CREATE