import { gql } from "@apollo/client";

const FOOD_DETAIL = gql`
  query food($id: ID!) {
    food(id: $id) {
      id
      foodDataId
      description
      dataType
      commonNames
      portions {
        modifier
        seqNum
        amount
        gramWeight
        unit
      }
    }
  }
`;

export default FOOD_DETAIL;
