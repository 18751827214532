import React, { useState } from 'react';

import MealSuggest from '../components/MealSuggest';

function Fooddata() {

  const [meals, setMeals] = useState([]);

  function addMeal(meal) {
    setMeals(meals.concat(meal));
  }

  return (
     <MealSuggest addMeal={addMeal}/>
  );
}

export default Fooddata