import React, { useState } from "react";

import {
  EuiFieldSearch,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiButton,
} from "@elastic/eui";

import IngredientResults from "../components/IngredientResults";
import IngredientCreateFlyout from "../components/IngredientCreateFlyout";

export default function Ingredients() {
  const [query, setQuery] = useState("");
  const [isCreateFlyoutVisible, setIsCreateFlyoutVisible] = useState(false);

  const close = () => {
    setIsCreateFlyoutVisible(false);
  };

  let createFlyout;

  if (isCreateFlyoutVisible) {
    createFlyout = <IngredientCreateFlyout close={close} query={query} />;
  }

  return (
    <>
      <EuiPageHeader alignItems="center">
        <EuiPageHeaderSection>
          <EuiTitle size="s">
            <h1>Ingredients</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection>
          <EuiButton
            color="primary"
            iconType="plusInCircle"
            aria-label="Add ingredient"
            size="s"
            onClick={() => setIsCreateFlyoutVisible(true)}
          >
            Create new ingredient
          </EuiButton>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiFieldSearch
        placeholder="Search..."
        fullWidth
        value={query}
        incremental={false}
        onChange={(e) => setQuery(e.target.value)}
      />

      <IngredientResults query={query} />

      {createFlyout}
    </>
  );
}
