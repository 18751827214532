import React, { useState } from "react";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiButtonIcon,
  EuiConfirmModal,
} from "@elastic/eui";
import AislesSelect from "./AisleSelect";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import INGREDIENTS from "../querys/ingredients";

const UPDATE_INGREDIENTS = gql`
  mutation updateIngredients($ingredients: [ID]!, $aisle: String) {
    updateIngredients(ingredients: $ingredients, aisle: $aisle) {
      success
    }
  }
`;

const DELETE_INGREDIENTS = gql`
  mutation deleteIngredients($ingredients: [ID]!) {
    deleteIngredients(ingredients: $ingredients) {
      success
    }
  }
`;

export default function IngredientMultiEditFlyout({
  selectedItems,
  query,
  close,
}) {
  const [aisle, setAisle] = useState(null);
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);

  const [updateIngredients, { loading, error }] = useMutation(
    UPDATE_INGREDIENTS,
    {
      errorPolicy: "all",
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  const [deleteIngredients, { deleteLoading, deleteError }] = useMutation(
    DELETE_INGREDIENTS,
    {
      errorPolicy: "all",
      onCompleted(data) {
        console.log(data);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  let ingredientIds = selectedItems.map(function (i) {
    return i.id;
  });

  const _update = () => {
    let variables = { ingredients: ingredientIds, aisle: aisle };
    updateIngredients({
      variables: variables,
      refetchQueries: [
        {
          query: INGREDIENTS,
          variables: {
            query: query.query,
            first: query.first,
            after: query.after,
          },
        },
      ],
    });
    close();
  };

  const _delete = () => {
    let variables = { ingredients: ingredientIds };
    deleteIngredients({
      variables: variables,
      refetchQueries: [
        {
          query: INGREDIENTS,
          variables: {
            query: query.query,
            first: query.first,
            after: query.after,
          },
        },
      ],
    });
    close();
  };

  let destroyModal;

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiConfirmModal
        title="Delete Ingredient"
        onCancel={() => setIsDestroyModalVisible(false)}
        onConfirm={_delete}
        cancelButtonText="No, don't do it"
        confirmButtonText="Yes, do it"
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          You're about to delete <b>{selectedItems.length} Ingredients.</b>
        </p>
        <p>Are you sure you want to do this?</p>
      </EuiConfirmModal>
    );
  }

  if (error)
    return (
      <pre>
        Error:{" "}
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );
  if (deleteError)
    return (
      <pre>
        Error:{" "}
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </pre>
    );

  return (
    <EuiFlyout onClose={close} aria-labelledby="flyoutTitle">
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 id="flyoutTitle">Update {selectedItems.length} Ingredients</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiForm component="form">
          <EuiFormRow label="Aisle" helpText="Aisle used in shopping list">
            <AislesSelect
              value={aisle}
              onChange={(e) => setAisle(e.target.value)}
              name={"aisle"}
            />
          </EuiFormRow>

          <EuiSpacer />

          <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            responsive={false}
            wrap
          >
            <EuiFlexItem grow={false}>
              <EuiButton
                fill
                onClick={_update}
                isLoading={loading}
                disabled={loading}
              >
                Save
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                color="danger"
                iconType="trash"
                aria-label="delete ingredient"
                onClick={() => setIsDestroyModalVisible(true)}
                disabled={deleteLoading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </EuiFlyoutBody>
      {destroyModal}
    </EuiFlyout>
  );
}
