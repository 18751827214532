import React from "react";
import { EuiButton } from "@elastic/eui";
import { useMutation } from "@apollo/client";
import RECIPE_ANNOTATE_NUTRIENTS from "../mutations/recipeAnnotateNutrients";
import RECIPE_DETAIL from "../querys/recipeDetail";

import { navigate } from "@reach/router";

export default function RecipeCloneButton({ id }) {
  const [clone, { loading, error }] = useMutation(RECIPE_ANNOTATE_NUTRIENTS, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
      navigate(`/recipes/${data.recipeAnnotateNutrients.recipe.databaseId}`);
    },
    onError(error) {
      console.log(error);
    },
  });

  function recipeClone() {
    if (
      window.confirm(
        "Caution, this action can override the current nutrients!"
      ) === true
    ) {
      clone({ variables: { input: { recipe: id } },
        refetchQueries: [
        {
          query: RECIPE_DETAIL,
          variables: {id: id},
        },
      ],});
    }
  }

  if (error) return `Error! ${error}`;

  return (
    <EuiButton
      size="s"
      isLoading={loading}
      iconType="indexMapping"
      onClick={recipeClone}
      disabled={loading}
    >
      Re-Annotate Nutrients
    </EuiButton>
  );
}
