import React, { useState } from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const ALL_SOURCES = gql`
  {
    allSources
  }
`;

export default function SourceSelect({ item, setItem }) {
  const { loading, error, data } = useQuery(ALL_SOURCES);
  const [selectedOptions, setSelected] = useState([]);

  if (error) return `Error! ${error}`;

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (Array.isArray(selectedOptions) && selectedOptions.length) {
      setItem(selectedOptions[0].label);
    }
  };

  const options = [];
  if (data) {
    data.allSources.forEach((a) => options.push({ value: a, label: a }));
  }

  return (
    <EuiComboBox
      placeholder="Select source"
      options={options}
      selectedOptions={selectedOptions}
      onChange={onChange}
      singleSelection={{ asPlainText: true }}
      isLoading={loading}
    />
  );
}
