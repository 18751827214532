import React from "react";

import { EuiComboBox } from "@elastic/eui";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

const ALL_RESTRICTIONS = gql`
  {
    allRestrictions
  }
`;

export default function SourceSelect({ items, setItem }) {
  const { loading, error, data } = useQuery(ALL_RESTRICTIONS);

  if (error) return `Error! ${error}`;

  const options = [];
  if (data) {
    data.allRestrictions.forEach((tag) => options.push({ label: tag }));
  }

  return (
    <EuiComboBox
      placeholder="Restrictions"
      options={options}
      selectedOptions={items}
      onChange={(i) => setItem(i)}
      isClearable={true}
      isLoading={loading}
    />
  );
}
