import { gql } from "@apollo/client";

const INGREDIENT_LINES = gql`
  query ingredientLines($query: String, $ingredients: [String]) {
    ingredientLines(first: 100, query: $query, ingredients: $ingredients) {
      count
      edges {
        node {
          id
          ingredient
          isKnownIngredient
          ingredientLine {
            original
          }
          walmart {
            department
            aisle
            shelf
          }
          quantity {
            numeric
          }
          unit {
            original
          }
          usda {
            grams
            measureSource
            usdaId
            usdaName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default INGREDIENT_LINES;
