import React from "react";
import { EuiButton } from "@elastic/eui";
import { useMutation } from "@apollo/client";
import RECIPE_CLONE from "../mutations/recipeClone";

import { navigate } from "@reach/router";

export default function RecipeCloneButton({ recipeId }) {
  const [clone, { loading, error }] = useMutation(RECIPE_CLONE, {
    errorPolicy: "all",
    onCompleted(data) {
      console.log(data);
      navigate(`/recipes/${data.recipeClone.recipe.databaseId}`)
    },
    onError(error) {
      console.log(error);
    },
  });

  function recipeClone() {
    clone({ variables: { input: { recipeId: recipeId } } });
  }

  if (error) return `Error! ${error}`;

  return (
    <EuiButton
      size="s"
      isLoading={loading}
      iconType="copy"
      onClick={recipeClone}
      disabled={loading}
    >
      Clone
    </EuiButton>
  );
}
