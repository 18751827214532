import { gql } from "@apollo/client";

const FLAG_AS_NOT_GOOD_OPTION = gql`
mutation flagNotGoodOptionForMealPlan($recipeId:ID!){
	flagNotGoodOptionForMealPlan(recipeId: $recipeId) {
    success
  }
}
`;

export default FLAG_AS_NOT_GOOD_OPTION