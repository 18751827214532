import React from "react";

import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
} from "@elastic/eui";

function RowPortionFieldText({ list, i, item, onChangeList }) {
  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiFieldText
          key={i}
          name="modifier"
          value={item.modifier}
          placeholder="Modifier"
          onChange={(e) => {
            list[i].modifier = e.target.value;
            onChangeList([...list]);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          key={i}
          name="seqNum"
          value={item.seqNum}
          placeholder="Sequence Number"
          onChange={(e) => {
            list[i].seqNum = e.target.value;
            onChangeList([...list]);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          key={i}
          name="amount"
          placeholder="Amount"
          value={item.amount}
          onChange={(e) => {
            list[i].amount = e.target.value;
            onChangeList([...list]);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          key={i}
          name="gramWeight"
          placeholder="Grams Weight"
          value={item.gramWeight}
          onChange={(e) => {
            list[i].gramWeight = e.target.value;
            onChangeList([...list]);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          key={i}
          name="unit"
          placeholder="Unit"
          value={item.unit}
          onChange={(e) => {
            list[i].unit = e.target.value;
            onChangeList([...list]);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          color="danger"
          onClick={() => {
            list.splice(i, 1);
            onChangeList([...list]);
          }}
          iconType="crossInACircleFilled"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default RowPortionFieldText;
