import React from "react";

import { EuiProgress } from "@elastic/eui";

function MacrosProgress({ recipe }) {
  let max =
    recipe.caloriesPerServing.protein +
    recipe.caloriesPerServing.carbs +
    recipe.caloriesPerServing.fat;
  let omegas = Math.round(
    recipe.caloriesPerServing.dha +
      recipe.caloriesPerServing.dpa +
      recipe.caloriesPerServing.epa +
      recipe.caloriesPerServing.ala
  );
  return (
    <ul>
      <li>
        <b>Protein:</b> {Math.round(recipe.caloriesPerServing.protein)} kcal -{" "}
        {Math.round((recipe.caloriesPerServing.protein * 100) / max)}%
        <EuiProgress
          value={Math.round(recipe.caloriesPerServing.protein)}
          max={max}
          color="primary"
          size="s"
        />
      </li>
      <li>
        <b>Carbs:</b> {Math.round(recipe.caloriesPerServing.carbs)} kcal -{" "}
        {Math.round((recipe.caloriesPerServing.carbs * 100) / max)}%
        <EuiProgress
          value={Math.round(recipe.caloriesPerServing.carbs)}
          max={max}
          color="accent"
          size="s"
        />
      </li>
      <li>
        <b>Fat:</b> {Math.round(recipe.caloriesPerServing.fat)} kcal -{" "}
        {Math.round((recipe.caloriesPerServing.fat * 100) / max)}%
        <EuiProgress
          value={Math.round(recipe.caloriesPerServing.fat)}
          max={max}
          color="secondary"
          size="s"
        />
      </li>
      <li>
        <b>Omega3:</b> {omegas} kcal - {Math.round((omegas * 100) / max)}%
        <EuiProgress
          value={Math.round(omegas)}
          max={max}
          color="secondary"
          size="xs"
        />
      </li>
    </ul>
  );
}
export default MacrosProgress;
