import { gql } from "@apollo/client";

const FOODS = gql`
  query foods($query: String, $first: Int) {
    foods(query: $query, first: $first) {
      count
      edges {
        node {
          id
          foodDataId
          description
          dataType
          brandOwner
          householdServingFulltext
          brandedFoodCategory
          suggest
          promote
        }
      }
    }
  }
`;

export default FOODS;
