import React from "react";
import { useQuery } from "@apollo/client";

import { EuiBasicTable, EuiProgress, EuiCodeBlock } from "@elastic/eui";

import HISTORY from "../querys/history";

function RecipeHistory({ recipeId }) {
  const id = btoa(`Recipe:${recipeId}`);
  const { loading, error, data } = useQuery(HISTORY, {
    variables: { objectType: "RECIPE", id: id },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;

  const columns = [
    {
      field: "date",
      name: "Date",
      render: (date) => {
        return `${date}`;
      },
    },
    {
      field: "user",
      name: "User",
      render: (user) => {
        return `${user}`;
      },
    },
    {
      field: "action",
      name: "Action",
      render: (action) => {
        return `${action}`;
      },
    },
    {
      field: "change",
      name: "Change",
      width: "50%",
      render: (change) => {
        console.log(change)
        return (
          <EuiCodeBlock language="json">
            {JSON.stringify(JSON.parse(change),null,2)}
          </EuiCodeBlock>
        );
      },
    },
  ];

  const getRowProps = (item) => {
    const { id } = item;
    return {
      "data-test-subj": `row-${id}`,
      className: "customRowClass",
      onClick: () => console.log(`Clicked row ${id}`),
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  return (
    <EuiBasicTable
      items={data.allHistory}
      rowHeader="history"
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps}
    />
  );
}

export default RecipeHistory;
