import React, { Fragment, useState } from "react";

import {
  EuiFieldText,
  EuiButtonEmpty,
  EuiFormRow,
  EuiTextArea,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiIcon,
  EuiButtonIcon,
  euiDragDropReorder,
} from "@elastic/eui";

function ListField({ item, i, list, setList, addInput, parse, textArea }) {
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState(null);

  const parseItem = (ingredientLine) => {
    setIsLoading(true);
    const url =
      "https://ilp.exegol.suggestic.com/api/v2/nutrients_ingredient_lines/";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredientLines: [ingredientLine],
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoading(false);
          if (result.results) {
            console.log(result);
            result.results[0].meta
              ? setText(`${result.results[0].meta.usda_name}`)
              : setText(`⛔️ Couldn't find a match`);
          } else {
            setText("Error");
          }
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addInput();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      window.setTimeout(function () {
        form.elements[index + 2].focus();
      }, 10);
      e.preventDefault();
    }
  };

  const handleRemoveItem = (e) => {
    let newList = [...list];
    newList.splice(i, 1);
    setList(newList);
   };

  return (
    <EuiFormRow label="" helpText={text}>
      {textArea ? (
        <EuiTextArea
          rows={3}
          name="Name"
          value={item}
          onChange={(e) => {
            let newList = [...list];
            newList[i] = e.target.value;
            setList(newList);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      ) : (
        <EuiFieldText
          name="Name"
          prepend={<EuiIcon type="grab" />}
          append={<EuiButtonIcon iconType="cross" aria-label="Remove this" onClick={
            handleRemoveItem
          }/>}
          value={item}
          onChange={(e) => {
            let newList = [...list];
            newList[i] = e.target.value;
            setList(newList);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          isLoading={isLoading}
          onBlur={(e) => {
            if (item !== "" && parse) parseItem(item);
          }}
        />
      )}
    </EuiFormRow>
  );
}

function ListFieldText({
  list,
  setList,
  addText,
  parse = false,
  textArea = false,
}) {
  const addInput = () => {
    setList([...list, ""]);
  };

  const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
      const items = euiDragDropReorder(list, source.index, destination.index);
      setList(items);
    }
  };

  const lines = list.map((item, i) => {
    return (
      <EuiDraggable key={i} index={i} draggableId={`asdf-${i}`} spacing="m">
        <ListField
          item={item}
          i={i}
          list={list}
          setList={setList}
          addInput={addInput}
          key={`il-${i}`}
          parse={parse}
          textArea={textArea}
        />
      </EuiDraggable>
    );
  });

  return (
    <Fragment>
      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable droppableId="DROPPABLE_AREA_BARE" spacing="m">
          {lines}
        </EuiDroppable>
      </EuiDragDropContext>
      <EuiButtonEmpty
        color="primary"
        onClick={() => addInput()}
        iconType="plusInCircleFilled"
      >
        {addText}
      </EuiButtonEmpty>
    </Fragment>
  );
}

export default ListFieldText;
