
export const generateUniqueId = () => {
  return "id-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9);
};

// TODO: Use Environment variables instead of this
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const utils = {
  API_URL:
    process.env.NODE_ENV === "production"
      ? "https://greedo-server.exegol.suggestic.com"
      : "http://localhost:8000",
};

export default utils;
