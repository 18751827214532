import React, { useState } from "react";

import {
  EuiBasicTable,
  EuiHealth,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiText,
  EuiCodeBlock,
} from "@elastic/eui";

function BooleanFieldIndicator({ condition }) {
  const color = condition ? "success" : "danger";
  return <EuiHealth color={color}></EuiHealth>;
}

export default function WellnessGPTLogTable({ data, pagination, onChange }) {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [flyoutItem, setFlyoutItem] = useState(null);

  let flyout;

  const columns = [
    {
      field: "node.date",
      name: "Timestamp",
    },
    {
      field: "node.assistant.name",
      name: "Assistant",
    },
    {
      field: "node.interaction.interaction",
      name: "Interaction",
    },
    {
      field: "node.isAssistantTester",
      name: "Test",
      width: "50px",
      render: (isAssistantTester) => (
        <BooleanFieldIndicator condition={isAssistantTester} />
      ),
    },
    {
      field: "node.isInitialMessage",
      name: "Initial MSG",
      width: "80px",
      render: (isInitialMessage) => (
        <BooleanFieldIndicator condition={isInitialMessage} />
      ),
    },
    {
      field: "node.isUsingConstraints",
      name: "Constraints",
      width: "90px",
      render: (isUsingConstraints) => (
        <BooleanFieldIndicator condition={isUsingConstraints} />
      ),
    },
    {
      field: "node.isUsingKnowlageBase",
      name: "Knowlage Base",
      width: "100px",
      render: (isUsingKnowlageBase) => (
        <BooleanFieldIndicator condition={isUsingKnowlageBase} />
      ),
    },
    {
      field: "node.isUsingEscalations",
      name: "Escalations",
      width: "90px",
      render: (isUsingEscalations) => (
        <BooleanFieldIndicator condition={isUsingEscalations} />
      ),
    },
    {
      field: "node.language",
      name: "Lang",
    },
    {
      field: "node.toneOfVoice",
      name: "Tone",
    },
    {
      field: "node.user",
      name: "User",
    },
    {
      field: "node.userMessage",
      name: "User Message",
    },
    {
      field: "node.profile.id",
      name: "Profile ID",
    },
    {
      field: "node.model",
      name: "Model",
    },
    {
      field: "node.temperature",
      name: "Temperature",
    },
    {
      field: "node.time",
      name: "Took",
      render: (time) => {
        return `${Number(time.toFixed(2))} sec`;
      },
    },
  ];

  if (isFlyoutVisible) {
    console.log(flyoutItem);
    flyout = (
      <EuiFlyout ownFocus onClose={() => setIsFlyoutVisible(false)}>
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>{flyoutItem.date}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiText>
            <h3>GPT messages</h3>
          </EuiText>
          <EuiCodeBlock language="json">
            {JSON.stringify(JSON.parse(flyoutItem.gptMessages), null, 2)}
          </EuiCodeBlock>
          <EuiText>
            <h3>GPT response</h3>
          </EuiText>
          <EuiCodeBlock language="json">
            {JSON.stringify(JSON.parse(flyoutItem.gptResponse), null, 2)}
          </EuiCodeBlock>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  const getRowProps = (item) => {
    const { databaseId } = item.node;
    return {
      "data-test-subj": `row-${databaseId}`,
      className: "customRowClass",
      onClick: () => {
        setIsFlyoutVisible(true);
        setFlyoutItem(item.node);
      },
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item.node;
    const { field } = column;
    return {
      className: "customCellClass",
      "data-test-subj": `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  return (
    <>
      <EuiBasicTable
        compressed={true}
        items={data.wellnessGptLog.edges}
        rowHeader="rowHeaderXX"
        columns={columns}
        rowProps={getRowProps}
        cellProps={getCellProps}
        pagination={pagination}
        onChange={onChange}
      />
      {flyout}
    </>
  );
}
