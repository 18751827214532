import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiButton,
  EuiTitle,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFormRow,
  EuiFieldText,
  EuiSelect,
  EuiFieldNumber,
} from "@elastic/eui";
import { useReactFlow } from "reactflow";

const QUESTION_DELETE = gql`
mutation questionDelete(
  $input: QuestionDeleteInput!
) {
  questionDelete(input: $input) {
    success
  }
}
`;

export default function AnswerForm({
  node,
  isFlyoutVisible,
  toggleIsFlyoutVisible,
}) {
  let flyout;

  const options = [
    { value: "option_one", text: "Single-select" },
    { value: "option_two", text: "Multi-select" },
    { value: "option_three", text: "Open-text" },
    { value: "option_4", text: "Open-number" },
    { value: "option_5", text: "Date-picker" },
    { value: "option_6", text: "HTML" },
    { value: "option_7", text: "API" },
  ];
  const [ questionDelete ] = useMutation(QUESTION_DELETE)
  const [type, setType] = useState(options[0].value);
  const [value, setValue] = useState("");
  const reactFlowInstance = useReactFlow();

  const close = () => {
    toggleIsFlyoutVisible(false);
  };

  const remove = async () => {
    const input = {id: node.id};

    try {
      const response = await questionDelete({ variables: { input } });
      if(response.data?.questionDelete?.success) {
        reactFlowInstance.deleteElements({nodes: [{id: node.id}]});
      }
    } catch (err) {
      console.error(err);
    }
    
  };

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout onClose={close} aria-labelledby="flyoutTitle" size="s">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="flyoutTitle">{node.nodeType}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow label="Text">
            <EuiFieldText
              id={"text-input"}
              value={node.text}
              onChange={(e) => {
                setType(e.target.value);
              }}
              aria-label="Use aria labels when no actual label is in use"
            />
          </EuiFormRow>
          {node.nodeType === "Question" && (
            <EuiFormRow
              label="Question type"
              helpText="Defines the type of question."
            >
              <EuiSelect
                id={"asdfasdf"}
                options={options}
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                aria-label="Use aria labels when no actual label is in use"
              />
            </EuiFormRow>
          )}

          {node.nodeType === "Answer" && (
            <EuiFormRow label="Scoring" helpText="Optional score value.">
              <EuiFieldNumber
                placeholder="0"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                aria-label="Scoring"
              />
            </EuiFormRow>
          )}

          <EuiFormRow label="Description" helpText="Optional for internal use.">
            <EuiFieldText />
          </EuiFormRow>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="cross" onClick={close} flush="left">
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="trash"
                onClick={remove}
                flush="left"
                color="danger"
              >
                Delete
              </EuiButtonEmpty>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton onClick={close} fill>
                Update
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return <div>{flyout}</div>;
}
