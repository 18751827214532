import { gql } from "@apollo/client";

const RECIPE_CLONE = gql`
  mutation recipeClone($input: RecipeCloneInput!) {
    recipeClone(input: $input) {
      success
      recipe {
        id
        databaseId
      }
    }
  }
`;

export default RECIPE_CLONE;
