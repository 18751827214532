import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import WELLNESS_GPT_LOG from "../querys/WellnessGPTLogs";
import { EuiProgress } from "@elastic/eui";

import WellnessGPTLogTable from "../components/WellnessGPTLogTable";

export default function WellnessGPTLog() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [cursor, setCursor] = useState(null);
  const { loading, error, data } = useQuery(WELLNESS_GPT_LOG, {
    variables: {
      first: pageSize,
      cursor: cursor,
    },
  });

  if (loading)
    return (
      <div>
        <EuiProgress size="xs" color="accent" />
      </div>
    );

  if (error) return `Error! ${error}`;


  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: data.wellnessGptLog.count,
    pageSizeOptions: [10, 20, 50, 100],
  };

  const onChange = ({ page = {} }) => {
    const { index: pageIndex, size: pageSize } = page;
    setPageIndex(pageIndex);
    setPageSize(pageSize);

    const cursorIndex = pageIndex * pageSize - 1;
    if (cursorIndex > 1) {
      setCursor(btoa(`arrayconnection:${cursorIndex}`));
    } else {
      setCursor(btoa(`arrayconnection:0`));
    }
  };

  return (
    <>
      <WellnessGPTLogTable
        data={data}
        pagination={pagination}
        onChange={onChange}
      />
    </>
  );
}
